import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import '../CryptoPage.css'; // Assuming you have a CSS file for styling
import { fontSize } from '@mui/system';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import { Helmet } from 'react-helmet';

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ButtonGroup,BottomNavigation,Paper,Chip, Button } from "@mui/material";
import SidebarMenuList from "../../includes/sidebarMenuList";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid, useStepContext } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import { ContactSupportOutlined, Recommend } from '@mui/icons-material';
import {Link, useLocation } from "react-router-dom";
import { Tabs, Tab } from '@mui/material';
import LoginPopUp from '../loginpopup';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { keyframes } from '@mui/system';

// Define the keyframes for the left-right motion animation
const leftRightMotion = `
  @keyframes leftRightMotion {
    0% {
      transform: translateX(-30px); /* Start 30px left of center */
    }
    50% {
      transform: translateX(30px);  /* Move 30px right of center */
    }
    100% {
      transform: translateX(-30px); /* Return to 30px left of center */
    }
  }
`;


const drawerWidth = 292;

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 900,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
}));

const Recommendations = (props) => {
  const { window1 } = props;
  const { crypto } = useParams();
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortType, setSortType] = useState('fd_asc');
  const [sortDropdownVisible, setSortDropdownVisible] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [predictionLoader, setPredictionLoader] = useState(false);
  const [slug, setSlug] = useState(false);
  const [sevenDays, setSevenDays] = useState(false);
  const [thirtyDays, setThirtyDays] = useState(false);
  const [yearDays, setYearDays] = useState(false);
  const [rowsToShow, setRowsToShow] = useState(7);
  const [selectedSort, setSelectedSort] = useState('');
  const sortRef = useRef();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [portfolioData, setPortfolioData] = useState([]);
  const location = useLocation(); 
  const loc = location.pathname;
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );

  const mediaQueryVar1 = useMediaQuery(
    json2mq({
      minWidth: 400,
    })
  );
  const mediaQueryVar2 = useMediaQuery(
    json2mq({
      maxWidth: 420,
    })
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 0,
    },
  }));

  const isSmallScreen = useMediaQuery(
    json2mq({
      maxWidth: 380,
    })
  );

  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");

    // Open the login modal
    setLoginModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal and reset the URL to the current page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
};

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
   
    "& td, & th": {
      border: 0,

    },
  }));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
};

const drawer = (
    <div className='d-flex flex-column h-100' style={{overflowY:'hidden'}}>
<SidebarMenuList/>
    </div>
);


const container = window1 !== undefined ? () => window1().document.body : undefined;

const {portfolioId, portfolioName } = useParams();
const mPortfolioName = portfolioName.replace(/-/g, ' ');
const [isTableView, setIsTableView] = useState(true);
const [selectedButton, setSelectedButton] = useState("CoinPrices");


useEffect(() => {
  const fetchCryptoData = async () => {
    try {
      const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/showCryptoRecommendationsFutureData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          portfolioName: mPortfolioName,
          sortType: sortType,
        }),
      });
      
      const result = await response.json();
      console.log(result);
      if (result.code === '200') {
        setData(result.response);
        if (result.response.length===0) {
          setPredictionLoader(true);
        }
        
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('An error occurred while fetching data.');
    } finally {
      setLoading(false);
    }
  };

  const fetchPortfolioData = async () => {
    try {
      const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/showPortfolioRecommendationsFutureData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          portfolioName: mPortfolioName,
          sortType: sortType,
        }),
      });


      const result = await response.json();
      console.log(result);
  
      if (result.code === '200') {
        console.log(result.data.recommended_portfolios.length);
        setPortfolioData(result.data.recommended_portfolios || []);
        // Check if portfolioData is set before setting loader
        if (result.data.recommended_portfolios.length===0) {
          setLoader(true);
        }
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('An error occurred while fetching data.');
    } finally {
      setLoading(false);
    }
  };

  fetchCryptoData();
  fetchPortfolioData();
}, [mPortfolioName, sortType, crypto]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setSortDropdownVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = (index) => {
    setDropdownVisible(dropdownVisible === index ? null : index);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (sortType) => {
    setSortType(sortType);
    setSortDropdownVisible(false);
    setSelectedSort(sortType);
  };

  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));


  const openModal = async (data) => {
    console.log(data.crypto_symbol);
    console.log(data.forecaster_name);
    if (data.accuracy.toLowerCase() !== 'pending') {
      try {
        const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/showCryptoRecommendationsPastData', { // Replace with your actual API endpoint
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            crypto_name: data.crypto_symbol,
            forecasterName: data.forecaster_name,
          }),
        });
  
        const result = await response.json();
        console.log(result);
  
        if (result.code === '200') {
          setModalData(result.response);
          setModalIsOpen(true);

        } else {
          setError(result.message);
        }
      } catch (err) {
        setError('An error occurred while fetching data.');
      }
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalData(null);
  };

  const filteredData = data;


  const handleLoadMore = () => {
    setRowsToShow(rowsToShow + 7);
  };


const compareValues = (val1, val2, order = 'asc') => {
  if (val1 === 'Pending') return 1;  // Ensure 'Pending' is always last
  if (val2 === 'Pending') return -1; // Ensure 'Pending' is always last
  if (order === 'asc') return val1 > val2 ? 1 : val1 < val2 ? -1 : 0;
  return val1 < val2 ? 1 : val1 > val2 ? -1 : 0;
};

const sortedData = [...filteredData].sort((a, b) => {
  switch (sortType) {
    case 'ac_asc':
      return compareValues(a.accuracy, b.accuracy, 'asc');
    case 'ac_desc':
      return compareValues(a.accuracy, b.accuracy, 'desc');
    default:
      return 0;
  }
});

const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (portfolioData && portfolioData.length > 0) {
      setIsDataLoaded(true); // Set to true when data is available
    }
  }, [portfolioData]);

  const handleButtonClick = (buttonName) => {
    // If the same button is clicked again, don't toggle the view
    if (selectedButton === buttonName) {
      return;
    }

    // Toggle the view based on the clicked button
    setSelectedButton(buttonName);
    setIsTableView(buttonName === "CoinPrices" ? false : true); // Show table view only if 'CoinPrices' is selected
  };

  const [isPredictionDataLoaded, setIsPredictionDataLoaded] = useState(false);
  useEffect(() => {
    if (sortedData && sortedData.length > 0) {
      setIsPredictionDataLoaded(true); // Set to true when data is available
    }
  }, [sortedData]);

  const currentUrl = window.location.pathname; // Get the current URL path

let url;

if (currentUrl.includes('/my-portfolio/')) {
  url = `/my-portfolio/${portfolioId}/${portfolioName}/detailed-portfolio-analysis`;
} else if (currentUrl.includes('/portfolio/')) {
  url = `/portfolio/${portfolioName}/detailed-portfolio-analysis`;
}

  return (
    <div className="crypto-page" style={{backgroundColor: mediaQueryVar? "#EDF1F6" : "white"}}>
      <Grid item xs={12} align="left">
      <div style={{ 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "space-between", // Changed from space-between to flex-end
        marginTop: "0px", 
        marginBottom: "10px",
        marginLeft: "5px"
    }}>
       <Helmet>
        <title>Recommendations</title>
</Helmet>
      {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
    {mediaQueryVar===false ? (
        <Box
        sx={{
          backgroundColor: "#1877F2", // Background color for the container
          padding: "3.105px", // Padding inside the container
          borderRadius: "38.042px", // Border radius for the container
          display: 'flex', // Ensure that the box is flexible
          alignItems: 'center', // Center the content vertically
          width: mediaQueryVar2?"300px":"330px", // Full width on extra small screens
          height: "36.63px",
          border: 'none',
          marginLeft: "-12px",
          marginTop: "3%",
          marginBottom:"5%"
        }}
      >
        <Tabs
          value={selectedButton}
          onChange={(event, newValue) => handleButtonClick(newValue)}
          aria-label="basic tabs example"
          sx={{
            flexGrow: 1, // Make the tabs take up remaining space
            "& .MuiTabs-indicator": {
              display: "none",
            },
            display: 'flex', // Ensure that the tabs are displayed in a row
            overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
          }}
        >
          <Tab
            label="Portfolio Analysis"
            value="PortfolioGallery"
            component={Link}
            to={url}
            sx={{
              whiteSpace: "nowrap",
              borderRadius: "20.962px",
              minHeight: "31.5px",
              height: "31.5px",
              fontWeight: selectedButton === "PortfolioGallery" ? "700" : "500",
              marginLeft: "1px",
              paddingLeft: "-15px",
              marginTop: "8px",
              border: "1px solid black",
              marginBottom: "-5px",
              fontSize: mediaQueryVar2?"13px":"15px",
              marginRight: "-15px",
              backgroundColor: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // White background for selected tab
              color: selectedButton === "PortfolioGallery" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
              textTransform: "none",
              border: "none"
            }}
          />
          <Tab
            label="Recommendations"
            value="CoinPrices"
            
            sx={{
              whiteSpace: "nowrap",
              borderRadius: "20.962px",
              minHeight: "31px",
              height: "31px",
              marginLeft: "5px",
              marginTop: "8px",
              border: "1px solid black",
              fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
              marginRight: "0px",
              fontSize: mediaQueryVar2?"13px":"15px",
              paddingRight: "-8px",
              textTransform: "none",
              backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
              color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
              border: 'none', // Remove border for tabs
            }}
          />
        </Tabs>
      </Box>
    ) : (null)}
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none"} }}
        >
            <img 
                src='/images/menubar.svg'
                alt="Menu Icon" 
                style={{ 
                    width: "24px", 
                    height: "24px",
                    marginRight: "10px",
                    marginTop: "10px" 
                }} 
            />
        </IconButton>
    </div>

</Grid>
<Drawer
    container={container}
    variant="temporary"
    open={mobileOpen}
    onClose={handleDrawerToggle}
    ModalProps={{
        keepMounted: true, // Better open performance on mobile.
    }}
    sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "400", backgroundColor: 'white', color: '#727376' },
    }}
>
    {drawer}
</Drawer>
{mediaQueryVar ? null : (
  <Typography
  variant="h6"
  component="div"
  style={{
    fontFamily: "Satoshi, sans-serif",
    fontWeight: 500,
    fontSize: mediaQueryVar2 ? "16px" : "18px",
    marginRight:"3px"
  }}
>
  <span style={{
    backgroundColor:"#1877f2",
    padding:"4px 4px",
    borderRadius:"5px",
    color:"white",
    marginLeft:'0px',
    
  }}> Top Portfolio</span> Recommendations
</Typography>

)}



{mediaQueryVar ? null : (
      !isDataLoaded && !loader ? (
        <div
  style={{
    position: "relative", 
    border: "1px solid #B6B6B8",
    margin: "3% 2% 2% 2%",
    padding: "2%",
    borderRadius: "8px",
    textAlign: "center",
    height: "25vh",
    overflow: "hidden", // Ensures content stays within the div,
    marginBottom:"30px"
  }}
>
  {/* Blurred background image */}
  <div style={{
    backgroundImage: `url(${window.constants.asset_path}/images/default-recommend-mobile.png)`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    filter: "blur(5px)",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
  }}></div>

  {/* Overlay content without blur */}
  <div style={{ 
    position: "relative", 
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }}>
    <style>
      {leftRightMotion}
    </style>
    <div style={{ position: "relative", width: "64px", height: "64px" }}>
      {/* Lens image with left-right motion animation */}
      <img 
        src={window.constants.asset_path + '/images/lens.png'}
        alt="Searching Lens"
        style={{
          width: "64px", 
          height: "64px", 
          position: "absolute",
          animation: `leftRightMotion 1.5s infinite ease-in-out`,
        }}
      />
    </div>
    <p style={{ fontFamily:"Satoshi,sans-serif", marginTop: "10px", fontSize: "18px", color: "black" }}>
      <b>Searching For The Best Crypto Portfolio, Personalized</b>
    </p>
  </div>
</div>

        ) : loader === true ? (
          <div style={{ marginBottom:"15%",border:"1px solid #B6B6B8",marginTop:"4%",textAlign: "center", color: "black", fontSize: "18px", backgroundColor: "white", padding: "2% ", borderRadius: "8px" }}>
            No Portfolio Recommendations at the moment.
          </div>
        ) : (
          portfolioData.map((portfolio, index) => (
            <React.Fragment key={index}>
            <Box 
            
              sx={{ 
                overflowY: "auto", 
                "&::-webkit-scrollbar": { display: "none" }, 
                scrollbarWidth: "none",
                filter: !userData && index >= 2 ? "blur(4px)" : "none",
                marginBottom: index === portfolioData.length - 1 ? "50px" : "12px" // Ensure this margin for the last element
              }}
            >
              <Box 
                sx={{ 
                  padding: "0", 
                  background:
        "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                  borderRadius: "6px", 
                  marginTop: "15px", 
                  marginBottom: "10px", 
                  border: "1px solid #DADEDF", 
                  marginLeft: "3px", 
                  marginRight: "5px" 
                }}
              >
                <Box sx={{ margin: "8px", marginRight: "16px",  }}>
                  <span style={{ color:  "black" }}>
                  {portfolio.portfolio_logo ? (
                  <img
                    src={window.constants.asset_path + portfolio.portfolio_logo}
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "33px",
                      border: "2px solid black",
                      marginRight:"2%"
                    }}
                    alt="Profile Pic"
                  />
                ) : (
                  <img
                    src={window.constants.asset_path + "/images/avtar.jpeg"}
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "33px",
                      border: "2px solid black",
                      marginLeft:"3px",
                     marginRight:"2%"
                    }}
                    alt="Default Profile Pic"
                  />
                )}
                  <a href={`${process.env.REACT_APP_HOME_URL}portfolio/${portfolio.portfolio_name.replace(/\s+/g, '-')}`} style={{color:"black"}}>{portfolio.portfolio_name}</a>
                  </span>
                </Box>
                <Box sx={{ background: "#fff", borderRadius: "6px", padding: "10px" }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "0px", borderBottom: "2px #DFDFDF solid" }}>
                    <Box sx={{ flex: 1, marginRight: "8px" }}>
                      <div style={{ display: "flex", margin: "4px", marginTop: "-5px" }}>
                        <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500", marginRight: "10px" }}>Last 7 Days:</span>
                        <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{portfolio.roi_7d}</span>
                      </div>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <div style={{ display: "flex", justifyContent: "space-between", margin: "4px", marginTop: "-5px" }}>
                        <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500", marginLeft: "20px" }}>Last 30 Days:</span>
                        <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{portfolio.roi_30d}</span>
                      </div>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between", paddingBottom: "5px", marginBottom: "0px" }}>
                    <Box sx={{ flex: 1, marginRight: "8px" }}>
                      <div style={{ display: "flex", margin: "4px", marginBottom: "-5px" }}>
                        <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500", marginRight: "10px" }}>Last 12 Months:</span>
                        <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>NA</span>
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            </React.Fragment>
          ))
        )
      )}

      
      {mediaQueryVar ? (
        
        <div className="layout">
          <div className>
          <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}

                    sx={{

                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376' },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{

                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376'},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            </div>
            
        <div className="content">
        <a href={`./`}>
            <Button size="small" color="primary" edge="start"  aria-label="menu"
            style={{
              borderRadius: "42px",
              padding:"10px 14px",
              backgroundColor:"#FFF",
              border:"1px solid #F0F0F0",
              width:"85px",
              height:"38px",
              marginLeft:"15px",
              marginBottom:"10px"
            }}>
            <img src={window.constants.asset_path + '/images/backarrow.svg'} alt="" /> 
            <span style={{fontSize:"14px",fontWeight:"500",color:"#727272",marginLeft:"8px",textTransform:"none"}}>Back</span>
            </Button>
            </a>
        <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginBottom: "20px",
                    marginTop: "5px",
                    
                  }}
                >

<Box
      sx={{
        backgroundColor: "#1877F2", // Background color for the container
        paddingTop: {xs:'10px', sm:'4px'},// Padding inside the container
        paddingLeft: {xs:'0px', sm:'0px'},
        paddingRight:{xs:'0px', sm:'0px'},
        paddingBottom: {xs:'0px', sm:'4px'},
        borderRadius: "50px", // Border radius for the container
        display: 'flex', // Make sure the box is flexible
        justifyContent: 'flex-start', // Center the content
        alignItems: 'center', // Center the content
        width: "395px",
        height:{xs:'44px', sm:'48px'},
        border:'none',
        marginLeft:"10px",
        marginTop:"10px"
      }}
    >
                  
                  <Tabs
  value={selectedButton}
  onChange={(event, newValue) => handleButtonClick(newValue)}
  aria-label="basic tabs example"
  sx={{
    alignSelf: mediaQueryVar === true ? "flex-start" : "",
    marginLeft: mediaQueryVar === true ? "2px" : "2px",
    borderRadius: "50px", // Adjust border radius for more rounded corners
    border: "none",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    display: 'flex', // Make sure the tabs are displayed in a row
    overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
  }}
>
  <Tab
    label="Portfolio Analysis"
    value="PortfolioGallery"
    component={Link}
            to={url}
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"1.1px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"2px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'28px', sm:'36px'},
      borderRadius: selectedButton === "PortfolioGallery" ? "20px" : "20px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: {xs:'13px', sm:'17px'},
      fontStyle: "normal",
      fontWeight: selectedButton === "PortfolioGallery" ? "700" : "500",
      
      backgroundColor: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButton === "PortfolioGallery" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', // Remove border for tabs
      background: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
  <Tab
    label="Recommendations"
    value="CoinPrices"
    
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"0px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"0px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'36px', sm:'36px'},
      borderRadius: selectedButton === "CoinPrices" ? "50px" : "50px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: "17px",
      fontStyle: "normal",
      fontWeight: selectedButton === "CoinPrices" ? "700" : "500", 
      
      backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', // Remove border for tabs
      background: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
</Tabs>

</Box>

                </Grid>
        <Typography
  variant="h6"
  component="div"
  style={{
    fontFamily: "Satoshi, sans-serif",
    fontWeight: "500",
    fontSize: "22px",
    marginRight:"3px",
    marginLeft:"2%"
  }}
>
  <span style={{
    backgroundColor:"#1877f2",
    padding:"4px 4px",
    borderRadius:"5px",
    color:"white",
    marginLeft:'0px',
    
  }}> Top Portfolio</span> Recommendations
</Typography>
    
                 {!isDataLoaded && !loader ? (
  // Show skeleton loaders while data is loading
 
    // Inside your component's JSX

<div
  style={{
    position: "relative", 
    border: "1px solid #B6B6B8",
    margin: "3% 2% 2% 2%",
    padding: "2%",
    borderRadius: "8px",
    textAlign: "center",
    height: "30vh",
    overflow: "hidden" // Ensures content stays within the div
  }}
>
  {/* Blurred background image */}
  <div style={{
    backgroundImage: `url(${window.constants.asset_path}/images/default-recommed.png)`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    filter: "blur(5px)",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
  }}></div>

  {/* Overlay content without blur */}
  <div style={{ 
    position: "relative", 
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }}>
    <style>
      {leftRightMotion}
    </style>
    <div style={{ position: "relative", width: "64px", height: "64px" }}>
      {/* Lens image with left-right motion animation */}
      <img 
        src={window.constants.asset_path + '/images/lens.png'}
        alt="Searching Lens"
        style={{
          width: "64px", 
          height: "64px", 
          position: "absolute",
          animation: `leftRightMotion 1.5s infinite ease-in-out`,
        }}
      />
    </div>
    <p style={{ fontFamily:"Satoshi,sans-serif", marginTop: "10px", fontSize: "18px", color: "black" }}>
      <b>Searching For The Best Crypto Portfolio, Personalized</b>
    </p>
  </div>
</div>



) : loader === true ? (
  <div style={{ marginBottom:"2%",marginLeft:"2%",marginRight:"2%",border:"1px solid #B6B6B8", marginTop:"4%",textAlign: "center", color: "black", fontSize: "18px", backgroundColor: "white", padding: "2% ", borderRadius: "8px" }}>
    No Portfolio Recommendations at the moment.
  </div>
) : (
  // Render the table when data is loaded and portfolioData is not empty
  <TableContainer 
    style={{ 
      width: "96%", 
      overflowX: "hidden", 
      border: "1px solid #DADEDF", 
      borderRadius: "5px", 
      padding: 0, 
      marginTop: "2%", 
      marginBottom: "5%" ,
      marginLeft:"2%",
    }}
  >
    <div style={{ backgroundColor: "white", padding: "2%", borderRadius: "8px"}}>
      <Table className="non_responsiveTable" aria-label="customized table" style={{ borderCollapse: "collapse"}}>
        <TableHead>
          <TableRow>
            <StyledTableCell 
              className="cryptotableviewHeader" 
              style={{ 
                borderRadius: "8px 0 0 8px", 
                border: 0, 
                padding: 0, 
                width: "25%" 
              }}
            >
              <button 
                disabled 
                style={{ 
                  textAlign: "left", 
                  color: "#727376", 
                  fontSize: "16px", 
                  width: "100%", 
                  height: "100%", 
                  border: "none", 
                  background: "white", 
                  padding: "12px 12px", 
                  margin: "0px 2px 0px 2px", 
                  boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" ,
                  fontWeight:"500"
                }}
              >
                Portfolio Name
              </button>
            </StyledTableCell>
            <StyledTableCell 
              className="cryptotableviewHeader" 
              style={{ padding: 0, width: "auto" }}
            >
              <button 
                disabled 
                style={{ 
                  textAlign: "center", 
                  color: "#727376", 
                  fontSize: "16px", 
                  width: "100%", 
                  height: "100%", 
                  border: "none", 
                  background: "white", 
                  padding: "12px 12px", 
                  margin: "0px 0px 0px 2px", 
                  boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" ,
                  fontWeight:"500"
                }}
              >
                Last 7 Days
              </button>
            </StyledTableCell>
            <StyledTableCell 
              className="cryptotableviewHeader" 
              style={{ padding: 0, width: "auto" }}
            >
              <button 
                disabled 
                style={{ 
                  textAlign: "center", 
                  color: "#727376", 
                  fontSize: "16px", 
                  width: "100%", 
                  height: "100%", 
                  border: "none", 
                  background: "white", 
                  padding: "12px 12px", 
                  margin: "0px 0px 0px 2px", 
                  boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" ,
                  fontWeight:"500"
                }}
              >
                Last 30 Days
              </button>
            </StyledTableCell>
            <StyledTableCell 
              className="cryptotableviewHeader" 
              style={{ padding: 0, width: "auto" }}
            >
              <button 
                disabled 
                style={{ 
                  textAlign: "center", 
                  color: "#727376", 
                  fontSize: "16px", 
                  width: "100%", 
                  height: "100%", 
                  border: "none", 
                  background: "white", 
                  padding: "12px 12px", 
                  margin: "0px 0px 0px 2px", 
                  boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" ,
                  fontWeight:"500"
                }}
              >
                Last 12 Months
              </button>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {portfolioData.map((portfolio, index) => (
             <React.Fragment key={index}>
            <StyledTableRow 
            style={{ 
              backgroundColor: index % 2 === 0 ? "white" : "#F0F0F0", 
              borderTop:"7px solid white",
              filter: !userData && index >= 2 ? "blur(4px)" : "none",
              }}>
              <StyledTableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: "none",
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: "500",
                  paddingLeft: "16px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  borderRight: "1px solid white",
                  width: "43%",
                  padding:"1.5%",
                  color:  "black",
                  background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))"
                }}
              >
                <a href={`${process.env.REACT_APP_HOME_URL}portfolio/${portfolio.portfolio_name.replace(/\s+/g, '-')}`} style={{color:"black"}}>
                {portfolio.portfolio_logo ? (
        <img
          src={window.constants.asset_path + portfolio.portfolio_logo}
          style={{
            border: "2px solid black",
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            marginRight:"2%"
          }}
          alt="Profile"
        />
      ) : (
        <img
          src={window.constants.asset_path + "/images/avtar.jpeg"}
          style={{
            border: "2px solid black",
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            marginRight:"2%"
          }}
          alt="Default Avatar"
        />
      )}{portfolio.portfolio_name}
               </a> 
              </StyledTableCell>
              <StyledTableCell
                className="one"
                style={{
                  borderBottom: "none",
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: "500",
                  textAlign: "center",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  borderRight: "1px solid #DADEDF",
                  width: "239px"
                }}
              >
               {portfolio.roi_7d} 
              </StyledTableCell>
              <StyledTableCell
                className="one"
                style={{
                  borderBottom: "none",
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: "500",
                  textAlign: "center",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  borderRight: "1px solid #DADEDF",
                  width: "239px"
                }}
              >
                {portfolio.roi_30d} 
              </StyledTableCell>
              <StyledTableCell
                className="one"
                style={{
                  borderBottom: "none",
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: "500",
                  textAlign: "center",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  borderRight: "1px solid #DADEDF",
                  width: "239px"
                }}
              >
                NA 
              </StyledTableCell>
            </StyledTableRow>
           

                 </React.Fragment> 
          ))}
        </TableBody>
      </Table>
    </div>
  </TableContainer>
)}

  
        
    
    {isPredictionDataLoaded === false && predictionLoader ? (
        <div style={{ border:"1px solid #B6B6B8",margin:"0px 2%",marginTop:"4%",textAlign: "center", color: "black", fontSize: "18px", backgroundColor: "white", padding: "2% ", borderRadius: "8px" }}>
          No Crypto Recommendations at the moment.
        </div>
      ) : (
        <div>
        <Typography
  variant="h6"
  component="div"
  style={{
    fontFamily: "Satoshi, sans-serif",
    fontWeight: 500,
    fontSize: "22px",
    marginRight:"3px",
    marginLeft:"2%"
  }}
>
  <span style={{
    backgroundColor:"#1877f2",
    padding:"4px 4px",
    borderRadius:"5px",
    color:"white",
    marginLeft:'0px',
    
  }}> Top Crypto</span> Recommendations
</Typography>
        
        <div style={{ fontFamily: "Satoshi, sans-serif",backgroundColor: "white", padding: "0%", borderRadius: "8px", margin:"2%", marginRight:"4%"}}> 
  
  <table  className="predictions-table" style={{width:"100%"}}>
    <thead>
      <tr >
        <th style={{textAlign:"left", width:"15%", fontWeight: "500", color: "#727376", fontSize: "16px", paddingTop:"2%"}}>Crypto Name</th>
        <th style={{textAlign:"center", fontWeight: "500", color: "#727376", fontSize: "16px",paddingTop:"2%"}}>Current Price</th>
        <th style={{textAlign:"center", fontWeight: "500", color: "#727376", fontSize: "16px",paddingTop:"2%"}}>Average Forecast</th>
        <th style={{textAlign:"center", fontWeight: "500", color: "#727376", fontSize: "16px",paddingTop:"2%"}}>End Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
    {isPredictionDataLoaded === false ? (
        [...Array(10)].map((_, index) => (
          <tr key={index}>
            {[...Array(4)].map((_, cellIndex) => (
              <td key={cellIndex}>
                <Skeleton variant="rounded" height={40} />
              </td>
            ))}
          </tr>
        ))
      ) : (
        sortedData.slice(0, rowsToShow).map((data, index) => (
          <React.Fragment key={index}>
          <tr style={{filter: !userData && index >= 2 ? "blur(4px)" : "none"}}>
            <td style={{ width: "42%", background: 'var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))', 
              borderBottom: "6px solid #fff", padding: "5px 7px", color: "#000", fontSize: "16px", fontWeight: "500", marginLeft: "5px" }}>
              <img 
                alt="" 
                src={data.symbol_logo} 
                style={{ 
                  borderRadius: "19px", 
                  marginRight: "16px", 
                  border: "2px solid #000", 
                  width: "36px", 
                  height: "36px", 
                  margin: "2px",
                  marginLeft: "0.5%",
                  marginRight: "4%"
                }} 
              />
              <a href={`${process.env.REACT_APP_HOME_URL}crypto/${data.crypto_name}`} style={{ color: "black", marginLeft: "2%" }}>
  {data.crypto_symbol}
</a>
            </td>
            <td style={{ textAlign: "center", color: "black", fontWeight: "500", fontSize: "16px", paddingLeft: "10px" }}>
              {data.current_price}
            </td>
            <td style={{ textAlign: "center", color: "black", fontWeight: "500", fontSize: "16px", paddingLeft: "10px" }}>
              {data.forecast_price}
            </td>
            <td style={{ textAlign: "center", color: "black", fontWeight: "500", fontSize: "16px", paddingLeft: "10px" }}>
              {data.target_date}
            </td>
          </tr>
          {index === 1 && !userData && (
      <StyledTableRow
        style={{
          height: "auto",
          backgroundColor: "transparent",
          border: "none",
          position: "relative",
        }}
      >
        <StyledTableCell
          colSpan={5}
          style={{
            borderBottom: "none",
            padding: "0",
            position: "relative",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              const originalUrl = window.location.pathname + window.location.search;
              sessionStorage.setItem('originalUrl', originalUrl);
              console.log("original url: " + originalUrl);
              handleLoginClick();
            }}
            style={{
              color: "white",
              backgroundColor: "#1877F2",
              textTransform: "none",
              width: mediaQueryVar ? "auto" : "200px",
              height: mediaQueryVar ? "auto" : "45px",
              fontWeight: "600",
              fontSize: "18px",
              borderRadius: "30px",
              padding: "8px 24px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
              border: "none",
              cursor: "pointer",
              left:"21.5vw",
              right:"0%"
            }}
          >
            Login/Signup
          </Button>
        </StyledTableCell>
      </StyledTableRow>
    )}

                 </React.Fragment> 
        ))
      )}
      {rowsToShow < sortedData.length && (
        <StyledTableRow>
          <StyledTableCell colSpan={6} align="center">
            <div style={{ display: 'flex', justifyContent: 'center', padding: '2% 0' }}>
              <button 
                onClick={handleLoadMore} 
                className="load-more"
                style={{ 
                  backgroundColor: "rgba(67, 97, 238, 0.15)", // Ensure button background is white
                  borderRadius: "6px",
                  border: "1px solid rgba(67, 97, 238, 0.15)",
                  textTransform: "none",
                  color: "#4361ee",
                  height: "35px",
                  cursor: "pointer",
                  maxWidth: "360px",
                  width: "15%", // Use percentage for responsiveness
                  marginTop: "", // Adjust margin as needed
                  marginBottom: "-20px" // Adjust margin as needed
                }}
              >
                Explore More
              </button>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      )}
    </tbody>
  </table>
      
</div>
</div>
)}
      </div>
      </div>
      ) : (
        <div>
        {isPredictionDataLoaded === false && predictionLoader ? (
          <div style={{ border:"1px solid #B6B6B8",margin:"0px 2%",marginTop:"4%",textAlign: "center", color: "black", fontSize: "18px", backgroundColor: "white", padding: "2% ", borderRadius: "8px" }}>
            No Crypto Recommendations at the moment.
          </div>
        ) : (
      <div>
<Typography
  variant="h6"
  component="div"
  style={{
    fontFamily: "Satoshi, sans-serif",
    fontWeight: 500,
    fontSize: mediaQueryVar2 ? "16px" : "18px",
    marginRight:"3px",
    marginBottom:"4%"
  }}
>
  <span style={{
    backgroundColor:"#1877f2",
    padding:"4px 4px",
    borderRadius:"5px",
    color:"white",
    marginLeft:'0px',
    
  }}> Top Crypto</span> Recommendations
</Typography>
      
      <div style={{ position: 'relative', overflowX: 'auto', paddingBottom:"10%" }}>
      <table className="predictions-table">
        <thead>
          <tr>
            <th style={{minWidth: mediaQueryVar1===false ? "" : "110px" ,textAlign:"left", fontWeight: "500", color: "#727376", fontSize: "16px"}}>Crypto Name</th>
            <th style={{textAlign:"center", fontWeight: "500", color: "#727376", fontSize: "16px"}}>Current Price</th>
            <th style={{textAlign:"center", fontWeight: "500", color: "#727376", fontSize: "16px"}}>Average Forecast</th>
            <th style={{textAlign:"center", fontWeight: "500", color: "#727376", fontSize: "16px"}}>End Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {isPredictionDataLoaded === false ? (
    [...Array(10)].map((_, index) => (
      <tr key={index}>
        {[...Array(5)].map((_, cellIndex) => (
          <td key={cellIndex}>
            <Skeleton variant="rounded" height={40} />
          </td>
        ))}
      </tr>
    ))
  ) : (
    sortedData.slice(0, rowsToShow).map((data, index) => (
      <React.Fragment key={index}>
      <tr style={{filter: !userData && index >= 2 ? "blur(4px)" : "none"}}>
        <td
          style={{
            width: "23%",
            background: 'var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))',
            borderBottom: "6px solid #fff",
            padding: "5px 7px",
            color: "#000",
            fontSize: "16px",
            fontWeight: "500",
            marginLeft: "5px",
            minWidth: "100px",
          }}
        >
          <img
            alt=""
            src={data.symbol_logo}
            style={{
              borderRadius: "19px",
              marginRight: "16px",
              border: "2px solid #000",
              width: "34px",
              height: "34px",
              margin: "2px",
              marginLeft: "0.5%",
              marginRight: "7px",
            }}
          />
          <a href={`${process.env.REACT_APP_HOME_URL}crypto/${data.crypto_name}`} style={{ color: "black", marginLeft: "2%" }}>
  {data.crypto_symbol}
</a>
        </td>
        <td
          style={{
            textAlign: "center",
            color: "black",
            fontWeight: "500",
            fontSize: "14px",
            paddingLeft: "10px",
          }}
        >
          {data.current_price}
        </td>
        <td
          style={{
            textAlign: "center",
            color: "black",
            fontWeight: "500",
            fontSize: "14px",
            paddingLeft: "10px",
          }}
        >
          {data.forecast_price}
        </td>
        <td
          style={{
            textAlign: "center",
            color: "black",
            fontWeight: "500",
            fontSize: "14px",
            paddingLeft: "10px",
            minWidth:"125px",
          }}
        >
          {data.target_date}
        </td>
        
      </tr>
      { index === 2 && !userData && ( // Conditionally render the button row
                        <StyledTableRow
                          style={{
                            height: "auto",
                            backgroundColor: "transparent",
                            border: "none",
                            position: "relative",
                            zIndex: 100,
                          }}
                        >
                          {/* Absolute positioning for the button */}
                          <StyledTableCell
                            style={{
                              position: "absolute",
                              left: "50%",
                              top: "50%",
                              transform: "translate(-50%, -50%)",
                              padding: "0",
                              backgroundColor: "transparent",
                              borderBottom: "none",
                              width: "100%",
                              textAlign: "center",
                              zIndex: 100, // Ensure button is on top,
                            }}
                          >
                            <Button
                              onClick={() => {
                                const originalUrl = window.location.pathname + window.location.search;
                                sessionStorage.setItem('originalUrl', originalUrl);
                                console.log("original url: " + originalUrl);
                                handleLoginClick();
                              }}
                              style={{
                                color: "white",
                                backgroundColor: "#1877F2",
                                textTransform: "none",
                                width: mediaQueryVar ? "auto" : "200px",
                                height: mediaQueryVar ? "auto" : "45px",
                                fontWeight: "600",
                                fontSize: "18px",
                                borderRadius: "30px",
                                padding: "8px 24px",
                                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                                border: "none",
                                cursor: "pointer",
                                marginLeft:"-20%"
                              }}
                            >
                              Login/Signup
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      )}

                 </React.Fragment> 
    ))
  )}
          {rowsToShow < sortedData.length && (
  <StyledTableRow>
    <StyledTableCell colSpan={6} align="center" style={{ position: 'relative', height: '100px' }}>
      <div style={{ 
        position: 'absolute', 
        left: '50%', 
        transform: 'translateX(-50%)', 
        top: '50%', 
        transform: 'translate(-50%, -50%)',
        padding: '1% 0 7% 0' 
      }}>
        <button 
          onClick={handleLoadMore} 
          className="load-more"
          style={{ 
            backgroundColor: "rgba(67, 97, 238, 0.15)", 
            borderRadius: "6px",
            border: "1px solid rgba(67, 97, 238, 0.15)",
            textTransform: "none",
            color: "#4361ee",
            height: "35px",
            cursor: "pointer",
            maxWidth: "360px",
            width: "15%",
            marginTop: "",
            marginBottom: "-20px"
          }}
        >
          Explore More
        </button>
      </div>
    </StyledTableCell>
  </StyledTableRow>
)}


        </tbody>
      </table>
      </div>
      </div>
    )}
    </div>
      )}
      {mediaQueryVar === false && (


<div
  style={{
    position: "fixed",
    bottom: "0",
    backgroundColor: "white",
    width: "100%",
    zIndex: "100",
    marginBottom:"0px",
    marginLeft:"-5%",
  }}
>
<BottomNavigation showLabels>
  <BottomNavigationAction
        label="Gallery"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => 
          navigate("/my-portfolio")
        }
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
     <BottomNavigationAction
       label="Profile"
       onClick={() => 
      navigate("/account")
  }
  icon={<img src={loc==='/account'? "/images/dollarS.svg":"/images/dollarU.svg"} width={22} height={22} alt="Icon" style={{marginBottom : "-3px"}}/>}
/>
</BottomNavigation>
</div>
)}
      <Modal 
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
          marginLeft:mediaQueryVar===true ? "20%" :""
        },
        content: {
          align:"center",
          width:"80%",
          marginBottom:"20%",
          height:"auto",
          borderRadius: '8px',
          position: 'center',
        }
      }}
       isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Accuracy Details">
        <img
            className="frameIcon"
            alt=""
            onClick={closeModal}
            src="/images/frame17.svg"
            style={{ background: "#F3F3F3", width: "25px", height: "25px", marginTop:"1.5%", marginBottom:"1%"
            }}
         />
        <h4>Past Accuracy</h4>
        {modalData ? (
          <table className="modal-table">
            <thead>
              <tr>
                <th>Published On</th>
                <th>Forecast Date</th>
                <th style={{textAlign:"center"}}>Forecast Price</th>
                <th style={{textAlign:"center"}}>Accuracy</th>
              </tr>
            </thead>
            <tbody>
              {modalData.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.publish_date}</td>
                  <td >{detail.target_date} 11:59 PM</td>
                  <td style={{textAlign:"center"}}>{detail.forecast_price}</td>
                  <td style={{textAlign:"center"}}>{detail.accuracy}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No data available</p>
        )}
      </Modal>
    </div>
  );
      
};

export default Recommendations;