import React from "react";
import Box from "@mui/material/Box";
import VerifiedIcon from "@mui/icons-material/Verified";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Tabs, Tab } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import CreatableSelect from "react-select/creatable";
import { Link as RouterLink } from 'react-router-dom';
import { getCryporCurrencyList,getFetchdropdown } from "../../apihelper/cryptocurrencylist";
import { Helmet } from 'react-helmet';
import Toolbar from "@mui/material/Toolbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import ClearIcon from "@mui/icons-material/Clear"; // Import ClearIcon
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ButtonGroup } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import {
  Button,
  Grid,
  TextField,
  Card,
  BottomNavigation,
  Checkbox,
} from "@mui/material";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import {
  getPortfolioForCategoryGallery,
  getSymbolbyPortfolioid,
} from "../../apihelper/homepage";
import "./home.css";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingButton from "@mui/lab/LoadingButton";
import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import BarChartIcon from "@mui/icons-material/BarChart";
// import LOGO from './images/logo.png'
import {
  FormControlLabel,
  FormLabel,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  Popover,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { completeGoogleLogin } from "../../apihelper/login";
import CircularProgress from "@mui/material/CircularProgress";

// import CircularProgress from '@mui/material/CircularProgress';

import { copyToPortfolio } from "../../apihelper/portfolio";

import { getTotalunit } from "../../apihelper/cryptocurrencylist";

import Dialog from "@mui/material/Dialog";

import TuneIcon from "@mui/icons-material/Tune";
import { updateWatchlist } from "../../apihelper/profile";
import { useParams } from "react-router-dom";


export default function CategoryPortFolioGallery(props) {
  
  const { onButtonClick } = props;
  const {filter} = props;
  const { categoryName } = useParams();
  const formattedCategoryName = categoryName.replace(/-/g, ' ');
 // console.log("Category name : ",categoryName);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbaropen, setSnackbaropen] = React.useState(false);
  const [Usablewallet, setUsablewallet] = useState("");
  const [isRowHovered, setIsRowHovered] = useState(false);
  const [selectedFilterDate, setselectedFilterDate] = useState("");
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [galleryloaderbutton, setgalleryloaderbutton] = useState(true);
  const [hasSelectedOption, setHasSelectedOption] = useState(false);
  const [photogalleryloader, setphotogalleryloader] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [sortByROI, setSortByROI] = useState("desc");
  const [photoindex, setphotoIndex] = useState(0);
  const openfilter = Boolean(anchorEl);
  const [addMoregalleryLoader, setAddMoregalleryLoader] = useState(false);
 // const [filter, setFilter] = useState("");
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [gallarydata, setGallarydata] = useState([]);
  const [verifieddata, setverifieddata] = useState("");
  const [roidata, setroidata] = useState("");
  const [selecteddata, setselecteddata] = useState("");
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);
  const [loadermodal, setloadermodal] = useState(false);
  const [newOptionValue, setNewOptionValue] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [symbolslugdata, setsymbolslugdata] = useState([]);
  const [showModal, setShowModal] = useState(false); // Change the state name to showModal
  const [selectedOption, setSelectedOption] = useState([]);
  const [options, setOptions] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);
  const [dataslug, setdataslug] = useState("");
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [watchlistdata, setWatchlistdata] = useState([]);

  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // State to control button disabled state
  const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] =
    useState("loading...");
  const [severity, setSeverity] = useState("info");
  const [isChecked, setIsChecked] = useState(
    Array(symbolslugdata.length).fill(false)
  );
  const [unitValues, setUnitValues] = useState(
    Array(symbolslugdata.length).fill("")
  );
  const closesnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbaropen(false);
  };
  const handleButtonClick = (buttonName) => {
    // Pass the buttonName to the parent component's function
    onButtonClick(buttonName);
  };

  const [slugpopupdata, setSlugpopupdata] = useState({
    slugname: "",
    slug: "",
    units: 0,
  });

  const [dropDown, setdropDown] = useState([]);
  const [user_role, setuser_role] = useState("");

  const fetchDropdownOptions = async () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));


    getFetchdropdown({
      user_id: userData.user_id,
      
      Token:userData.token
    }).then((res) => {
      if (res.code === "200") {
        const newOptions = res.data.map(item => ({ value: item.id, label: item.name }));
          setOptions(newOptions);
          setuser_role(res.user_role);
       } else {
        console.log("Data fething error");
      }
    
    });
   
    
  }
  const [errorMessage, setErrorMessage] = useState('');

  
  const handleChange1 = async (selectedOption,slug) => {
    setIsOptionSelected(true);
    if (selectedOption && selectedOption._isNew_) {
      setIsDialogOpen(true);
      console.log('Creating new option:', selectedOption.value);
      setSelectedOption(null);
      setIsOptionSelected(false);
      setdataslug
      (slug)
    } 
    else if(!selectedOption) {
      // Handle the case when the selection is cleared
      setSelectedOption(null);
      setIsOptionSelected(false);
      setShowAlert(false);
      // Perform any other necessary actions
      return;
    }else{

      const selectedValue = selectedOption.value;
      if (selectedValue.includes('-')) {
        setErrorMessage('Hyphens are not allowed in the option.'); // Set error message
        setShowAlert(true); // Show the alert
        return; // Abort further processing
      }
      setShowAlert(false);
    setSelectedOption(selectedOption);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    getTotalunit({
      user_id: userData.user_id,
         slug:  slug, // Assuming value contains the slug
       portfolio_type_id: selectedOption.value ,// Adjust as needed
      Token:userData.token
    }).then((res) => {
      if (res.code === "200") {
        setTotalUnits(res.total_uint);
        setUsablewallet(res.wallet_amount);
       } else {
        console.log("Data fething error");
      }
      //console.log(slugdata)
    });
  }
  };
//console.log("Gallary Data: ",gallarydata);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCreate = async (inputValue) => {
   
    setTotalUnits(0);
    setUsablewallet("");

    if (options.find((option) => option.value === inputValue)) {
      return; // Exit early if the option already exists
    }
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    //console.log(inputValue);
    const newOption = { value: inputValue, label: inputValue };
    const newOptions = [...options, newOption]; // Create a new array with the existing options plus the new one
    setOptions(newOptions); // Update the options state
    const createdOption = newOptions.find(
      (option) => option.value == inputValue
    );
    //console.log(createdOption);
    if (createdOption) {
      handleChange(createdOption);
    }
  };


  const { window1 } = props;
  const container =
  window1 !== undefined ? () => window1().document.body : undefined;
const [selectedButton, setSelectedButton] = useState("PortfolioGallery");
const [isTableView, setIsTableView] = useState(true);



  const handleCloseeoption = () => {
    setAnchorEl(null);
  };
  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );
  const handleUnitChange = (event, idx) => {
    const { value } = event.target;
    setUnitValues((prevState) => {
      // Ensure prevState is converted to an array if it's not already
      const updatedUnitValues = Array.isArray(prevState) ? [...prevState] : [];
      updatedUnitValues[idx] = value; // Update the unit value for the specified index
      return updatedUnitValues;
    });
  };

  const handleCheckboxChange = (event, idx) => {
    const newChecked = [...isChecked];
    newChecked[idx] = event.target.checked;
    setIsChecked(newChecked);
    const anyChecked = newChecked.some((checked) => checked);
    setIsButtonEnabled(anyChecked);
  };
  const handlefilterDateChange = (value) => {
    setSelectedFilter(value);
    setselectedFilterDate(value);
    fetchData(value, isVerified, sortByROI); // Call fetchData when date changes
    setHasSelectedOption(true);
    handleCloseeoption();
  };
  const handleVerifiedChange = (event) => {
    setIsVerified(event.target.checked);

    fetchData(selectedFilterDate, event.target.checked, sortByROI); // Call fetchData when date changes
    setHasSelectedOption(true);
    handleCloseeoption();
  };

  const [localUserData,setLocalUserData]=useState(undefined)
  const [myString, setMyString] = useState("7 Days ROI");

  const handleClickOneDay = () => {
    handlefilterDateChange("yesterday");
    setMyString("1 Day ROI");
  };
  const handleClickSevenDay = () => {
    handlefilterDateChange("7days");
    setMyString("7 Days ROI");
  };
  const handleClickThirtyDay = () => {
    handlefilterDateChange("30days");
    setMyString("1 Month ROI");
  };
  const handleClickAnnualized = () => {
    handlefilterDateChange("annual");
    setMyString("Annualized ROI");
  };

useEffect(()=>{
setphotoIndex(0);
setphotogalleryloader(false);
if ((filter.length < 1) & (filter.length >= 1)) {
  setphotogalleryloader(true);
  setAddMoregalleryLoader(false);
}
else{
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const user_id = userData ? userData.user_id : 0;
  getPortfolioForCategoryGallery({
    user_id: user_id,
    categoryName : formattedCategoryName,
    search_text: filter,
    value: selecteddata,
      is_verified: verifieddata ? "yes" : "no",
      sort_by_roi: roidata,
    start_index: 0,
    limit: `${7}`,
  }).then((res) => {
    if (res.code === "200") {
      //console.log("data got successfully", res.data);
      setGallarydata(res.data);
      //console.log("Resutant data: ",gallarydata);

      if (res.data.length < 7) {
        setgalleryloaderbutton(false);
      } else {
        setgalleryloaderbutton(true);
      }
      setphotogalleryloader(true);
      setAddMoregalleryLoader(false);
    } else {
      console.log("Data fething error");
    }
       //console.log(slugpopupdata)
  });

}

},[filter]);

useEffect(()=>{
  
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const user_id = userData ? userData.user_id : 0;
    setLocalUserData(userData);
    getPortfolioForCategoryGallery({
      user_id: user_id,
      search_text: filter,
      categoryName : formattedCategoryName,
      value: selecteddata,
      is_verified: verifieddata ? "yes" : "no",
      sort_by_roi: roidata,
      start_index: 0,
      limit: `${7}`,
    }).then((res) => {
      if (res.code === "200") {
        //console.log("data got successfully", res.data);
       

        setGallarydata(res.data);
  
        if (res.data.length < 7) {
          setgalleryloaderbutton(false);
        } else {
          setgalleryloaderbutton(true);
        }
        setphotogalleryloader(true);
        setAddMoregalleryLoader(false);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  
  }
  
  ,[]);
  
  


  useEffect(() => {
    fetchphotogallery();
  }, []);
  const fetchphotogallery = () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const user_id = userData ? userData.user_id : 0;

    setphotogalleryloader(false);
    getPortfolioForCategoryGallery({
      user_id: user_id,
      search_text: filter,
      categoryName : formattedCategoryName,
      start_index: `${photoindex}`,
      limit: `${7}`,
    }).then((res) => {
      if (res.code === "200") {
        console.log("data got successfully");
        setGallarydata(res.data);

        if (res.data.length < 7) {
          setgalleryloaderbutton(false);
        } else {
          setgalleryloaderbutton(true);
        }
        setphotogalleryloader(true);
        setAddMoregalleryLoader(false);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };





  
  const loadMoregallery = () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const user_id = userData ? userData.user_id : 0;
    setAddMoregalleryLoader(true);
    setphotoIndex(photoindex + 7);
    // console.log(index);

    getPortfolioForCategoryGallery({
      user_id: user_id,
      search_text: filter,
      categoryName : formattedCategoryName,
      value: selecteddata,
      is_verified: verifieddata ? "yes" : "no",
      sort_by_roi: roidata,
      start_index: photoindex + 7,
      limit: `${7}`,
    }).then((res) => {
      if (res.code === "200") {
        console.log("data got successfully");
        setGallarydata([...gallarydata, ...res.data]);

        if (res.data.length < 7) {
          // console.log("change Loaderbutton");
          setgalleryloaderbutton(false);
        } else {
          setgalleryloaderbutton(true);
        }
        setphotogalleryloader(true);
        setAddMoregalleryLoader(false);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };



  const [showAlert, setShowAlert] = useState(false);
  
  const fetchData = (selectedFilDate, isVerifieddata, sortBy) => {
    setphotoIndex(0);
    setphotogalleryloader(false);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const user_id = userData ? userData.user_id : 0;
    setselecteddata(selectedFilDate);
    setroidata(sortBy);
    setverifieddata(isVerifieddata);
    getPortfolioForCategoryGallery({
      user_id: user_id,
      search_text: filter,
      categoryName : formattedCategoryName,
      value: selectedFilDate,
      is_verified: isVerifieddata ? "yes" : "no",
      sort_by_roi: sortBy,
      start_index: `${0}`,
      limit: `${7}`,
    }).then((res) => {
      if (res.code === "200") {
        console.log("data got successfully");
        setGallarydata(res.data);

        if (res.data.length < 7) {
          setgalleryloaderbutton(false);
        } else {
          setgalleryloaderbutton(true);
        }
        setphotogalleryloader(true);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleSortByROIChange = (event) => {
    setSortByROI(event.target.value);
    setHasSelectedOption(true);
    fetchData(selectedFilterDate, isVerified, event.target.value); // Call fetchData when date changes
    handleCloseeoption();
  };

  var rows = [],
    i = 0,
    len = 15;
  while (++i <= len) rows.push(i);

  const handleClearAll = () => {
    setIsVerified(false);
    setSortByROI("desc");
    setselectedFilterDate("");
    handleCloseeoption();
    setHasSelectedOption(false);
    fetchData();
    setSelectedFilter(null);
    setMyString("7 Days ROI");
  };

  useEffect(() => {
    if (dropDown) {
      fetchDropdownOptions();
    }
  }, [dropDown]);

  const handleOpenSelect = () => {
    setShowAlert(false);
    setdropDown(true);
  };


  const [starredPortfolios, setStarredPortfolios] = useState({});

  const handleStarClick = (portfolio_id) => {
    //console.log("portf_id", portfolio_id);

    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const user_id = userData.user_id;
    const token = userData.token;

    // Toggle the starred state for this portfolio
    setStarredPortfolios(prevState => {
        const isStarred = prevState[portfolio_id];

        // If currently starred, remove from local storage and watchlist
        const updatedState = {
            ...prevState,
            [portfolio_id]: !isStarred // Toggle star state for this portfolio ID
        };

        // Save the updated starred portfolios to local storage
        localStorage.setItem("starred_portfolios", JSON.stringify(updatedState));

        // Update the watchlist in the backend
        updateWatchlist({
            user_id,
            portfolio_id,
            token,
            action: isStarred ? 'remove' : 'add'
        }).then(res => {
            if (res.code === "200") {
                //console.log("Watchlist updated successfully:", res.data);
                setWatchlistdata(res.data);
            } else {
                console.log("Data Fetching Error");
            }
        });

        return updatedState;
    });
};

// Load saved starred portfolios from local storage when the component mounts
useEffect(() => {
    const savedStarredPortfolios = JSON.parse(localStorage.getItem("starred_portfolios"));
    if (savedStarredPortfolios) {
      setStarredPortfolios(savedStarredPortfolios);
    }
}, []);

  
  
 
  const handleeyeclick = (portfolioName) => {
    // Replace spaces with hyphens
    const formattedName = portfolioName.replace(/\s+/g, '-');
  
    // Navigate to the route '/portfolio' with the formattedName parameter
    navigate(`/portfolio/${formattedName}`);
  };
  

  const handleCopy = (portfolio_id) => {
    //console.log("gallery portfolio ID: ", portfolio_id);
    setloadermodal(true);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    if (!userData) {
      navigate("/login");
      return; // Return early to prevent further execution
    }
    setUnitValues({});
    setShowModal(true);
    getSymbolbyPortfolioid({
      user_id: userData.user_id,
      portfolio_id: portfolio_id,
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
       
        setsymbolslugdata(res.data);
        setloadermodal(false);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };


  const handleChange2 = (e) => {
    const value = e.target.value;
    // Check if the input contains a hyphen
    if (value.includes('-')) {
      setShowAlert(true); // Show the alert if there's a hyphen
    } else {
      setShowAlert(false); // Hide the alert if there's no hyphen
      setNewOptionValue(value); // Update the state with the new value
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTotalUnits(0);
    setUsablewallet("");
    setSelectedOption([]);
  };

  const handleChange = async (selectedOption, slug) => {
    if (selectedOption && selectedOption.__isNew__) {
      setIsDialogOpen(true);
     // console.log("Creating new option:", selectedOption.value);
      setSelectedOption(null);
      setdataslug(slug);
    } else if (!selectedOption) {
      // Handle the case when the selection is cleared
      setSelectedOption(null);
      setShowAlert(false);
      // Perform any other necessary actions
      return;
    }else{

      const selectedValue = selectedOption.value;
      if (selectedValue.includes('-')) {
        setErrorMessage('Hyphens are not allowed in the option.'); // Set error message
        setShowAlert(true); // Show the alert
        return; // Abort further processing
      }
      setShowAlert(false);

    setSelectedOption(selectedOption);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    getTotalunit({
      user_id: userData.user_id,
      slug: slug, // Assuming value contains the slug
      portfolio_type_id: selectedOption.value, // Adjust as needed
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        setTotalUnits(res.total_uint);
        setUsablewallet(res.wallet_amount);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  }
  };

  const handleClickphotoGallery = () => {
    const checkedData = symbolslugdata.filter((obj, idx) => isChecked[idx]);
    if (checkedData.length > 0) {
      setMsgforUpdatePortfolio("loading...");

      setSnackbaropen(true);
      setShowModal(true);
      setSeverity("info");
      // setSnackbarcolor("success");
      // setSnackbarmessage("Data saved successfully");
      setMsgforUpdatePortfolio("loading...");
      // console.log(price, selectedDate, buySell, unit);
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      const portfolioId =
        selectedOption && !isNaN(parseInt(selectedOption.value))
          ? parseInt(selectedOption.value)
          : 0;
      const existingPortfolio = portfolioId > 0 ? "yes" : "no";

      const checkedUnitValues = Array.isArray(unitValues)
        ? unitValues.filter((unit, idx) => isChecked[idx])
        : [];

        
      const portfolioData = checkedData.map((obj, idx) => ({
        slug: obj.slug_name,
        unit: checkedUnitValues[idx] || "",
      }));
      
      copyToPortfolio({
        portfolio_type_id: portfolioId,
        portfolio_name: selectedOption ? selectedOption.label : "", // Ensure selectedOption is defined
        portfolio_data: portfolioData,

        existing_portfolio: existingPortfolio,
        user_id: userData.user_id,

        Token: userData.token,
      }).then((res) => {
        if (res.code === "200") {
          setUnitValues({});
          setShowModal(false);

          setSeverity("info");
          console.log(res.message);
          setMsgforUpdatePortfolio(res.message);
          setIsChecked(new Array(symbolslugdata.length).fill(false));
          navigate(
            `/my-portfolio/${res.portfolio_type_id}/${encodeURIComponent(
              selectedOption.label
            )}`
          );
          setSelectedOption([]);
          setTotalUnits(0);
          setUsablewallet("");
        } else {
          setSeverity("error");
          setSelectedOption([]);
          setMsgforUpdatePortfolio(res.message);
          
        }
      });
    } else {
      setSeverity("error");
      setSelectedOption([]);
    }
  };
  const handleDialogSubmit = () => {
    // Handle submitting the dialog (e.g., creating the new option)
   // console.log("Creating new option:", newOptionValue);

    // Update options state with the new option
    const newOption = { value: newOptionValue, label: newOptionValue };
    setOptions([...options, newOption]);
    setSelectedOption(newOption);
    // Close the dialog
    setIsDialogOpen(false);

    // setSelectedOption(selectedOption);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    getTotalunit({
      user_id: userData.user_id,
      slug: dataslug, // Assuming value contains the slug
      portfolio_type_id: newOption.value, // Adjust as needed
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        setTotalUnits(res.total_uint);
        setNewOptionValue("");
        setUsablewallet(res.wallet_amount);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };
  return (
    <>
{mediaQueryVar===true?(
  
  <div>  
   
          <Box
            sx={{
              borderRadius: "10px",
              display: "flex",
             
              justifyContent: "flex-end",
              marginBottom: "5px",
              color:"#1877F2",
              marginTop:"-68px"
            }}
          >
            <Button
              startIcon={ 
                <img src={window.constants.asset_path + '/images/filter_main.svg'}/> 
              }
              endIcon={
                <img src={window.constants.asset_path + '/images/arrowdown.svg'}/>}
              variant="outlined"
              sx={{
                "&:hover": {
                  background:"rgba(24, 119, 242, 0.04)"
                  , // Set the hover color
                  border: "1px solod #1877F2",
                },

                background:"rgba(24, 119, 242, 0.04)",
                width:"auto",
                height:"36.86px",
                color: "#1877F2",
                padding: "6px 12px",

                textTransform: "none",
                borderRadius: "6px",
                border: "1px #1877F2 solid",
                position: "reletive", // Position absolutely
                marginRight:"30px",
                marginBottom:"18px"
                 // Align to the right
              }}
              onClick={handlePopoverOpen}
            >
              {hasSelectedOption && (
                <IconButton size="small">
                  <CheckCircleOutlineIcon style={{ color: "green" }} />
                </IconButton>
              )}
             <span style={{
              fontFamily:"Satoshi, sans-serif",
              fontSize:"16px",
              fontWeight:"400",
              fontColor:"#1877F2"
             }}>Filters</span> 
            </Button>

            <Popover
              open={openfilter}
              anchorEl={anchorEl}
              onClose={handleCloseeoption}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem
                selected={selectedFilter === "yesterday"}
                onClick={handleClickOneDay}
              >
                1 Day
              </MenuItem>
              <MenuItem
                selected={selectedFilter === "7days"}
                onClick={handleClickSevenDay}
              >
                1 Week
              </MenuItem>
    
              <MenuItem
                selected={selectedFilter === "30days"}
                onClick={handleClickThirtyDay}
              >
                1 Month
              </MenuItem>

              <MenuItem
                selected={selectedFilter === "annual"}
                onClick={handleClickAnnualized}
              >
                Annualized ROI
              </MenuItem>
              <Divider />
              <ListSubheader>Verification status</ListSubheader>
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={isVerified}
                      onChange={handleVerifiedChange}
                    />
                  }
                  label={
                    <Typography sx={{ fontWeight: "500" }}>
                      Is Verified
                    </Typography>
                  }
                />
              </MenuItem>
              <Divider />
              <ListSubheader>Sort ROI</ListSubheader>
              <RadioGroup
                aria-label="verification-status"
                name="verification-status"
                value={sortByROI}
                onChange={handleSortByROIChange}
              >
                <MenuItem>
                  <FormControlLabel
                    value="desc"
                    control={<Radio size="small" />}
                    label={
                      <Typography sx={{ fontWeight: "500" }}>
                        High to Low
                      </Typography>
                    }
                  />
                </MenuItem>
                <MenuItem>
                  <FormControlLabel
                    value="asc"
                    control={<Radio size="small" />}
                    label={
                      <Typography sx={{ fontWeight: "500" }}>
                        Low to High
                      </Typography>
                    }
                  />
                </MenuItem>
              </RadioGroup>

              <Box sx={{ display: "flex", padding: "8px" }}>
                <Button
                  size="small"
                  sx={{ textTransform: "capitalize", marginLeft: "10px" }}
                  onClick={handleClearAll}
                  startIcon={<ClearIcon />}
                >
                  Clear All
                </Button>
              </Box>
            </Popover>
          </Box>
        </div>

):(
<div>  
          <Box
            sx={{
              borderRadius: "10px",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "5px",
              
              color:"#1877F2",
            }}
          >
            <Button
              
              onClick={handlePopoverOpen}
              
            >
              
              <div style={{marginTop:"-170px",
              marginRight:"-10px",
              marginBottom:"-80px",border:"2px solid #1877F2",borderRadius:"50%",
                padding:"8px 9px"}}>
              <img width="20px"
              src={window.constants.asset_path + '/images/filter.svg'}/></div>
            </Button>

            <Popover
              open={openfilter}
              anchorEl={anchorEl}
              onClose={handleCloseeoption}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
             <MenuItem
                selected={selectedFilter === "yesterday"}
                onClick={handleClickOneDay}
              >
                1 Day
              </MenuItem>
              <MenuItem
                selected={selectedFilter === "7days"}
                onClick={handleClickSevenDay}
              >
                1 Week
              </MenuItem>
    
              <MenuItem
                selected={selectedFilter === "30days"}
                onClick={handleClickThirtyDay}
              >
                1 Month
              </MenuItem>

              <MenuItem
                selected={selectedFilter === "annual"}
                onClick={handleClickAnnualized}
              >
                Annualized ROI
              </MenuItem>
              <Divider />
              <ListSubheader>Verification status</ListSubheader>
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={isVerified}
                      onChange={handleVerifiedChange}
                    />
                  }
                  label={
                    <Typography sx={{ fontWeight: "500" }}>
                      Is Verified
                    </Typography>
                  }
                />
              </MenuItem>
              <Divider />
              <ListSubheader>Sort ROI</ListSubheader>
              <RadioGroup
                aria-label="verification-status"
                name="verification-status"
                value={sortByROI}
                onChange={handleSortByROIChange}
              >
                <MenuItem>
                  <FormControlLabel
                    value="desc"
                    control={<Radio size="small" />}
                    label={
                      <Typography sx={{ fontWeight: "500" }}>
                        High to Low
                      </Typography>
                    }
                  />
                </MenuItem>
                <MenuItem>
                  <FormControlLabel
                    value="asc"
                    control={<Radio size="small" />}
                    label={
                      <Typography sx={{ fontWeight: "500" }}>
                        Low to High
                      </Typography>
                    }
                  />
                </MenuItem>
              </RadioGroup>

              <Box sx={{ display: "flex", padding: "8px" }}>
                <Button
                  size="small"
                  sx={{ textTransform: "capitalize", marginLeft: "10px" }}
                  onClick={handleClearAll}
                  startIcon={<ClearIcon />}
                >
                  Clear All
                </Button>
              </Box>
            </Popover>
          </Box>
        </div>

)}


{mediaQueryVar === true ? (




<TableContainer style={{ overflowX: "auto", borderRadius: "5px", margin: "0 auto", width: "100%", paddingRight:"30px" }}>
  <div style={{ border: "1px solid #EEE", borderRadius: "5px", overflow: "hidden", width: "100%" }}>
    <Table
      className="non_responsiveTable" aria-label="customized table"
      style={{ borderCollapse: "collapse", width: "100%" }}
    >
      <TableHead>
        <TableRow>
          <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width: "50vw" }}>
            <button disabled style={{
              fontWeight: "500", textAlign: "left", color: "#727376", fontSize: "16px", width: "100%", height: "45px",
              border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
            }}>
              Profile Name
            </button>
          </StyledTableCell>

          <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width: "20vw" }}>
            <button disabled style={{
              fontWeight: "500", textAlign: "center", color: "#727376", fontSize: "16px", width: "100%", height: "45px",
              border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
            }}>
              Watchlist
            </button>
          </StyledTableCell>

          <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width: "25vw" }}>
            <button disabled style={{
              fontWeight: "500", textAlign: "center", color: "#727376", fontSize: "16px", width: "100%", height: "45px",
              border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
            }}>
              Actions
            </button>
          </StyledTableCell>

          <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width: "25vw" }}>
            <button disabled style={{
              fontWeight: "500", textAlign: "center", color: "#727376", fontSize: "16px", width: "100%", height: "45px",
              border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
            }}>
             {myString}
            </button>
          </StyledTableCell>
        </TableRow>
      </TableHead>

      {photogalleryloader === false && (
        <TableBody>
          {rows.map((idx) => (
            <StyledTableRow key={idx}>
              <StyledTableCell>
                <Stack>
                  <Skeleton
                    variant="rounded"
                    sx={{ marginTop: '10px' }}
                    height={40}
                  />
                </Stack>
              </StyledTableCell>
              <StyledTableCell>
                <Stack>
                  <Skeleton
                    variant="rounded"
                    sx={{ marginTop: '10px' }}
                    height={40}
                  />
                </Stack>
              </StyledTableCell>
              <StyledTableCell>
                <Stack>
                  <Skeleton
                    variant="rounded"
                    sx={{ marginTop: '10px' }}
                    height={40}
                  />
                </Stack>
              </StyledTableCell>
              {mediaQueryVar === true && (
                <StyledTableCell>
                  <Stack>
                    <Skeleton
                      variant="rounded"
                      sx={{ marginTop: '10px' }}
                      height={40}
                    />
                  </Stack>
                </StyledTableCell>
              )}

              {mediaQueryVar === false && (
                <StyledTableCell>
                  <Stack>
                    <Skeleton
                      variant="rounded"
                      sx={{ marginTop: '10px' }}
                      width={mediaQueryVar === false ? '' : 200}
                      height={40}
                    />
                  </Stack>
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      )}

      {photogalleryloader && (
        <TableBody>
          {gallarydata.map((row, index) => (
            <TableRow
              key={index}
              onClick={() => {
                handleeyeclick(row.portfolio_name);
                setIsRowClicked(true);
              }}
              onMouseEnter={() => setHoveredRowIndex(index)}
              onMouseLeave={() => setHoveredRowIndex(-1)}
              sx={{
                backgroundColor: index % 2 === 0 ? "white" : "#F0F0F0",
                cursor: "pointer"
              }}
            >
              <StyledTableCell
  component="th"
  scope="row"
  style={{
    borderBottom: "none",
    color: "#000",
    fontSize: "16px",
    fontWeight: "500",
    paddingLeft: "16px",
    paddingTop: "6px",
    paddingBottom: "6px",
    borderRight: "1px solid #DADEDF",
    backgroundColor: "white",
    wordWrap: "wrap",
  }}
>
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      background:
        "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
      padding: "12px 7px",
      margin: "0 -5px -10px -20px",
    }}
  >
    <Box>
      {row.photo ? (
        <img
          src={window.constants.asset_path + row.photo}
          style={{
            border: "2px solid black",
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            marginLeft: "5px",
            marginBottom: "3px",
          }}
          alt="Profile"
        />
      ) : (
        <img
          src={window.constants.asset_path + "/images/avtar.jpeg"}
          style={{
            width: "38px",
            height: "38px",
            borderRadius: "33px",
            border: "2px solid black",
            marginLeft: "5px",
            marginBottom: "3px",
          }}
          alt="Default Avatar"
        />
      )}
    </Box>
    {row.is_verified === "yes" && (
      <VerifiedIcon
        sx={{
          backgroundColor: "white",
          marginTop: "18px",
          borderRadius: "10px",
          color: "#1A91E4",
          fontSize: 20,
          marginLeft: "-15px",
          zIndex: 999,
          flexShrink: 0,
          strokeWidth: "0.5px",
          filter: "dropShadow(0px 2px 5.5px rgba(0, 0, 0, 0.10))",
        }}
      />
    )}
    <Box sx={{ marginLeft: "5px" }}>
      <Typography
        sx={{
          color: "#000",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        {row.portfolio_name}
      </Typography>
      <Box
        sx={{
          
          display: "flex",
          gap: "5px",
          flexWrap: "wrap",
          marginTop: "5px",
        }}
      >
        {row.slugs.map((tag) => (
          <Box
            key={tag.slug}
            sx={{
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "2px 11px",
              fontSize: "12px",
              fontWeight: "500",
              color: "#000",
              marginBottom:"4px"
            }}
          >
            {tag.category}
          </Box>
        ))}
      </Box>
    </Box>
  </Box>
</StyledTableCell>
<StyledTableCell style={{
  borderBottom: "none",
  color: "#000",
  fontSize: "16px",
  fontWeight: "500",
  paddingLeft: "16px",
  paddingTop: "8px",
  paddingBottom: "8px",
  borderRight: "1px solid #DADEDF",
  textAlign: "center"
}}>
  <img
    width="28.62px"
    height="18px"
    src={starredPortfolios[row.portfolio_id] ? `${window.constants.asset_path}/images/selectedStar.svg` : `${window.constants.asset_path}/images/star.svg`}
    alt="View Icon"
    onClick={(e) => {
      e.stopPropagation();
      handleStarClick(row.portfolio_id, row.portfolio_name);
    }}
    style={{
      cursor: 'pointer',
      width: '24px',
      height: '20px',
    }}
  />
</StyledTableCell>


  <StyledTableCell style={{
  borderBottom: "none",
  color: "#000",
  fontSize: "16px",
  fontWeight: "500",
  paddingLeft: "16px",
  paddingTop: "8px",
  paddingBottom: "8px",
  borderRight: "1px solid #DADEDF",
  alignItems: "center"
}}>
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '64px' }}>
      <img
        width="28.62px"
        height="18px"
        src={window.constants.asset_path + '/images/eye.svg'}
        alt="View Icon"
        onClick={(e) => {
          e.stopPropagation();
          handleeyeclick(row.portfolio_name);
        }}
        style={{
          cursor: 'pointer',
          width: '24px',
          height: '20px',
          marginRight: row.display_copy === 'yes' ? '30px' : '0px',
        }}
      />
      {row.display_copy === 'yes' && (
        <img
          width="18px"
          height="18px"
          src={window.constants.asset_path + '/images/copy-01.svg'}
          alt="Copy Icon"
          onClick={(e) => {
            e.stopPropagation();
            handleCopy(row.portfolio_id);
          }}
          style={{
            cursor: 'pointer',
            width: '24px',
            height: '20px',
          }}
        />
      )}
    </Box>
  </Box>
</StyledTableCell>



              <StyledTableCell style={{
                borderBottom: "none",
                color: "#000",
                fontSize: "16px",
                fontWeight: "500",
                paddingLeft: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
                borderRight: "1px solid #DADEDF",
                textAlign:"center"
              }}>
                {row.roi_value}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      )}
    </Table>
  </div>

  <div style={{ textAlign: 'center', marginTop: '20px' }}></div>
  {gallarydata.length === 0 && photogalleryloader === true && (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <h4>No Data Found</h4>
    </div>
  )}
  {photogalleryloader && galleryloaderbutton && (
    <div className="loadingButton">
      <LoadingButton
        loading={addMoregalleryLoader}
        loadingPosition="center"
        variant="outlined"
        style={{
          maxWidth: '360px',
          background: "rgba(24, 119, 242, 0.04)",
          borderRadius: '6px',
          border: '1px #1877F2 solid',
          textTransform: 'none',
          borderColor: '#1877F2',
          color: '#4361ee',
          width: mediaQueryVar === false ? '80vw' : '140px',
        }}
        onClick={() => {
          loadMoregallery();
        }}
      >
        <span style={{ color: '#1877F2' }}>Explore More</span>
      </LoadingButton>
    </div>
  )}
</TableContainer>


):(

<TableContainer sx={{ 
  borderRadius: "10px",
  marginTop: "-20px",
  marginLeft: "-20px",
  maxHeight: "65vh", 
  overflowX: "hidden",
  overflowY: "auto",
  marginBottom:"30px",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  // Hides scrollbar for Firefox
  scrollbarWidth: "none", 
}}>
  {photogalleryloader && (
    <Stack>
      {gallarydata.map((row, index) => (
        

          <Card
            key={index}
            sx={{marginBottom:"10px",
              marginLeft:"0px",
              boxShadow: "none",
            }}
            
          >
            <Box sx={{
              borderRadius: "6px",
              marginTop: "9px",
              marginBottom:"9px",
              marginLeft:"20px",
              marginRight:"0px",
              paddingTop: "3px",
              paddingBottom:"3px",
              paddingLeft:"5px",
              paddingRight:"5px",
              border: "1px solid #DADEDF",
              
              background: 'var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))',
              minheight:"150px",
              
            }}>
          <RouterLink
          key={index}
          to={`../portfolio/${row.portfolio_name.replace(/ /g, "-")}`}
          
          
          style={{ textDecoration: "none", color: "inherit" ,}}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              
              <Box>
                {row.photo ? (
                  <img
                    src={window.constants.asset_path + row.photo}
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "33px",
                      border: "2px solid black"
                    }}
                    alt="Profile Pic"
                  />
                ) : (
                  <img
                    src={window.constants.asset_path + "/images/avtar.jpeg"}
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "33px",
                      border: "2px solid black",
                      marginLeft:"3px",
                     
                    }}
                    alt="Default Profile Pic"
                  />
                )}
              </Box>
              
              {row.is_verified === "yes" && (
                  <VerifiedIcon
                    sx={{
                      color: "#1877F2",
                      fontSize: 20,
                      zIndex: 999,
                      marginTop:"22px",
                      marginLeft:"-15px",
                      background:"white",
                      borderRadius:"50%"
                    }}
                  />
                )}

              <Box sx={{ flexGrow: 1 }}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    variant="h6"
                    sx={{ color: "black", fontWeight: "500", fontSize: "16px", padding: "10px",fpntFamily:"Satoshi,sans-serif" }}
                  >
                    {row.portfolio_name}
                  </Typography>
                  
                  <RouterLink
                   to={`../portfolio/${row.portfolio_name.replace(/ /g, "-")}`}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Typography variant="body2">
                      <img
                        src={`${window.constants.asset_path}/images/link.svg`} // Example path
                        alt="Link Symbol"
                        style={{
                          width: "10px",
                          height: "15px",
                          marginLeft: "8px",
                          
                        }}
                      />
                      
                    </Typography>

                    
                  </RouterLink>
                  
    
                  
                </Stack>
               
              </Box>
              
            </Stack>
            
            </RouterLink>
            <Box
        sx={{
          display: "flex",
          gap: "5px",
          flexWrap: "wrap",
          marginTop: "3px",
          marginLeft:"50px"
        }}
      >
        {row.slugs.slice(0, 3).map((tag) => (
          <Box
            key={tag.slug}
            sx={{
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "2px 11px",
              fontSize: "11px",
              fontWeight: "500",
              color: "#000",
              
              marginBottom:"5px"
            }}
          >
            {tag.category}
          </Box>
        ))}
      </Box>
            </Box>
            
            <Divider sx={{ my: 1 }} />

            <Box
    sx={{
      background: "white",
      borderRadius: "6px",
      marginLeft:"20px",
      marginTop:"-20px",
      display: "flex",
      
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: "36px",
      height: "36px",
      //border: "1px solid #1877F2",
      //borderTop: "1px solid #1877F2", // Added border-top
      borderBottom: "1px solid #1877F2", // Added border-bottom
      borderLeft: "1px solid #1877F2", // Added border-left
      borderRight: "1px solid #1877F2", // Added border-right
    }}
  >
              <Typography sx={{ color: "#76787A", fontWeight: "500", fontSize: "13px", padding: "10px",marginBottom:"4px",fontFamily:"Satoshi,Arial,sans-serif" }}>
                {myString}:
                <span
                  style={{
                    color: "black",
                    fontWeight: "500",
                    fontSize: "14px",
                    paddingLeft: "10px",
                  }}
                >
                  {row.roi_value}
                </span>
              </Typography>

              
    <img
    width="28.62px"
    height="18px"
    src={starredPortfolios[row.portfolio_id] ? `${window.constants.asset_path}/images/selectedStar.svg` : `${window.constants.asset_path}/images/star.svg`}
    alt="View Icon"
    onClick={(e) => {
      e.stopPropagation();
      handleStarClick(row.portfolio_id, row.portfolio_name);
    }}
    style={{
      cursor: 'pointer',
      width: '24px',
      height: '20px',
      marginRight:'-100px'
    }}
  />
             
              <Button
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopy(row.portfolio_id);
                }}
                sx={{ marginRight: "-10px" }}
              >
                <img
                  src={window.constants.asset_path + "/images/mobile_copy.svg"}
                  alt="Copy Icon"
                  style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                    marginBottom:"5px",
                    marginTop:"3px"
                  }}
                />
              </Button>
            
            </Box>
          
          </Card>
      ))}
    </Stack>
  ) }
  {gallarydata.length === 0 && photogalleryloader && (
    <Typography align="center" variant="h6">
      No Data Found
    </Typography>
  )}
  {photogalleryloader && galleryloaderbutton && (
    <Box sx={{ textAlign: "center", mt: 2 }}>
      <LoadingButton
        loading={addMoregalleryLoader}
        loadingPosition="center"
        variant="outlined"
        style={{
          
          width: "60%",
          backgroundColor: "rgba(67, 97, 238, 0.15)",
          borderRadius: "6px",
          border: "none",
          textTransform: "none",
          borderStyle: "solid",
          borderColor: "rgba(67, 97, 238, 0.15)",
          borderWidth: "1px",
          color: "#4361ee",
          marginBottom:"70px"
        }}
        onClick={loadMoregallery}
      >
        <span style={{ color: "#4361ee" }}>Explore More</span>
      </LoadingButton>
    </Box>
  )}

</TableContainer>

)}
      

      <Dialog
      open={showModal}
      onClose={handleCloseModal}
      PaperProps={{
        sx: {
          position: mediaQueryVar ===false ? 'fixed' : 'static',
          bottom: mediaQueryVar === false? '0' : '',
          left: mediaQueryVar === false? '0' : '',
          right: mediaQueryVar === false? '0' : '',
          margin: '0',
          maxWidth: '100%',
          minHeight: '50%', // Adjust max height if needed
          borderRadius:mediaQueryVar === false? "24px 24px 0 0":"24px",
          padding:mediaQueryVar === false? '10px' : '10px',
          alignItems:"center",
          overflow:"hidden"
        }
      }}
    >
      {mediaQueryVar === false &&(
      <div style={{ display: 'flex', justifyContent: 'center' }}>
   <button style={{
    width: '48px',
    height: '4px',
    borderRadius: '100px',
    background: '#E2E2E2',
    border: 'none',
    margin: '10px',

  }}></button></div>)}
      <div style={{ padding: '20px', width: mediaQueryVar==false?'100%':'400px', margin: '0',maxHeight:"589px", }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px',marginTop:"-10px" }}>
          <Typography style={{ fontWeight: '500', fontSize: '20.767px', paddingLeft:"20%" }} gutterBottom>
            Add To Portfolio
          </Typography>
          <div className="primaryButton">
          <img
            className="frameIcon"
            alt=""
            onClick={handleCloseModal}
            src="images/frame17.svg"
            style={{ background: "#F3F3F3", width: "25px", height: "25px", marginRight: mediaQueryVar === false? '20px' : '',
            }}
         />
          </div>
        </div>
        <div style={{ 
          display: 'flex', 
          marginLeft: mediaQueryVar === false? '0px' : '',
          marginRight: mediaQueryVar === false? '0px' : '',

          flexDirection: 'column', marginBottom: '10px', }}>
          <Typography style={{ fontSize:"13.845px",fontWeight: '500', marginBottom: '5px', 
            color:'black',
             }}>
            Select Portfolio
          </Typography>
          <div style={{ marginTop: '5px' }}>
            {slugpopupdata.slugname}
            <CreatableSelect
                  isClearable
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: '12px',
                      border: '1px solid #1877F2',
                      boxShadow: 'none',
                      minHeight: '40px',
                      
                    }),
                    menu: (provided) => ({
                      ...provided,
                      borderRadius: '8px',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: '',
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: '#1877F2', // Add color to the dropdown indicator
                      border:"1px solid #1877F2",
                      padding:"5px",
                      margin:"8px",
                      borderRadius:"3.786px",
                      '&:hover': {
                        color: '#1877F2', // Add hover color to the dropdown indicator
                      },
                    }),
                    indicatorSeparator: (provided) => ({
                      display: 'none', // Hide the vertical mark
                    }),
                  }}
                  placeholder=""
                  onClick={(e) => handleOpenSelect()}
                  onChange={(options) => handleChange1(options)}

                  onCreateOption={handleCreate}
                options={[
                  { value: '', label: 'Create new portfolio', _isNew_: true }, // Dynamic "create new option" item
                  ...options // Existing options
                ]}
                  value={selectedOption}
                  
                />
          </div>
          {showAlert && (
        <Alert severity="error" sx={{ marginBottom: '10px' }}>
          {errorMessage}
        </Alert>
        
      )}
        </div>
        <Typography style={{ fontSize: '13.845px', fontWeight: '500', color: '#000', marginTop: '16px', marginBottom: '10px',marginLeft:mediaQueryVar ===false?'0px':'' }}>
          Usable Wallets:
          <span style={{ fontSize: '12px', color: 'gray', marginTop: '-10px', marginBottom: '10px',marginLeft:"10px" }}>
          {Usablewallet}</span>
        </Typography>
            
        <div style={{maxHeight:"150px" ,overflowY :"scroll",
          marginLeft:mediaQueryVar ===false?'0px':'',
          marginRight:mediaQueryVar ===false?'0px':'',
          display: 'flex', flexDirection: 'column', 
          marginBottom: '10px',
          '&::-webkit-scrollbar': {
              display:"none"
              },
               '-ms-overflow-style': 'none',  
               'scrollbar-width': 'none'
          
          }}>
          
          {loadermodal ? (
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {symbolslugdata.map((obj, idx) => (
                <div key={idx} style={{ width:"100%",padding: "8px", background: '#F3F3F3', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                {/* Slug Details */}
                {/* Checkbox */}
                <Checkbox  style={{ display: 'flex', alignItems: 'center', flex: 0, minWidth: 0, marginRight:"10px" }} checked={isChecked[idx]} onChange={(event) => handleCheckboxChange(event, idx)} />
                <div style={{ display: 'flex', alignItems: 'center', flex: 0, minWidth: 0 }}>
                  <img style={{ marginRight: '10px', border: "2px solid #000", borderRadius: "19px", height: "32px", width: "33.493px" }} alt="" src={obj.photo} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontWeight: '500', marginBottom: '1px', fontSize: "11.249px", color: "#7F7F80" }}>{obj.slug_name}</Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: '500', color: 'black', margin: '0' }}>{obj.slug_title}</Typography>
                  </div>
                </div>
            
                {/* Price Details */}
                <div style={{ display: 'flex', flexDirection: 'column', marginRight:"-100px",alignItems: 'center', flex: 1 }}>
                  <Typography sx={{ fontWeight: '500', marginBottom: '2px', fontSize: "11.249px", color: "#7F7F80" }}>PRICE</Typography>
                  <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>{obj.current_price}</Typography>
                </div>
                <div style={{width:"30%"}}>
                <TextField
                  size="small"
                  InputProps={{
                    style: {
                    width: "100%",
                    height: "40px",
                    borderRadius:"12px",
                    border:"1px solid #1877F2",
                    textAlign: "center",
                    fontSize: "11px", // Adjust padding to decrease height
                    lineHeight: "1", // Ensure single line height for the input
                    boxShadow: "none", // Remove shadow if any
                    background: "#FFF",
                    fontWeight:"600",
                    fontfamily:"arial"
                    },
                  }}
                  type="number"
                  placeholder="Enter number of Units"
                  required
                  className="placeholder-text"
                  value={unitValues[idx] || ""} // Set the value from state
                  onChange={(event) => handleUnitChange(event, idx)} // Pass index to handleUnitChange
                />
                </div>
              </div>
              ))}
            </>
          )}
        </div>
        <Button
          style={{color:"white",marginLeft:mediaQueryVar ===false?'10px':'',
            marginRight:mediaQueryVar ===false?'10px':'',background: '#1877F2', textTransform:"none",width: mediaQueryVar ===false?'95%':'100%', height:"45px", marginTop: '8px', fontWeight: '700', fontSize: '15.575px', borderRadius:"23.363px" }}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleClickphotoGallery}
          disabled={!isButtonEnabled}
        >
          Buy Now
        </Button>
       
      
        {!isOptionSelected && ( // Conditionally render the "Create new portfolio" button
        <Button
          style={{
            marginBottom: mediaQueryVar === false ? '0px' : '',
            marginLeft: mediaQueryVar === false ? '10px' : '',
            marginRight: mediaQueryVar === false ? '10px' : '',
            width: mediaQueryVar === false ? '95%' : '100%',
            border: "1px solid #1877F2", borderRadius: "23.363px", marginTop: "10px", color: "#1877F2", textTransform: "none", padding: '10px', fontWeight: '700', fontSize: '15.575px'
          }}
          variant="outline"
          size="large"
          onClick={() => setIsDialogOpen(true)}
          disabled={isButtonEnabled}
        >
          Create new portfolio
        </Button>
      )}
          
      </div>
    </Dialog>

    <Dialog
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '40px' }}>
        Create New Portfolio
        <IconButton
          aria-label="close"
          onClick={() => {
            setIsDialogOpen(false);
            setNewOptionValue("");
          }}
          style={{ position: "absolute", right: '13px', top: '8px' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
      <div>
      <TextField
        autoFocus
        label="Enter Name"
        value={newOptionValue}
        onChange={handleChange2}
        fullWidth
        margin="dense"
      />
      {showAlert && (
        <Alert severity="error" sx={{ marginTop: '8px' }}>
          Hyphens are not allowed in the name.
        </Alert>
      )}
    </div>
      </DialogContent>
      <DialogActions style={{ marginRight: '14px' }}>
        <Button
          onClick={() => {
            setIsDialogOpen(false);
            setNewOptionValue("");
          }}
          style={{ fontWeight: 'bold' }}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleDialogSubmit}
          variant="contained"
          color="primary"
          style={{ fontWeight: 'bold' }}
          disabled={!newOptionValue.trim()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
      <Snackbar
        open={snackbaropen}
        autoHideDuration={5000}
        onClose={closesnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
      </Snackbar>

   
    </>
  );
}
