import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { EditText } from "react-edit-text";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Helmet } from 'react-helmet';
import { useState } from "react";
import { Button, Grid,Dialog, DialogActions, DialogContent, DialogTitle, TextField, Card, CardContent } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { BottomNavigation,Paper,Chip } from "@mui/material";
import json2mq from "json2mq";
import { Link } from 'react-router-dom';
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import MenuListWhitepaper from "../includes/menulistwhitepaper";



const drawerWidth = 292;

function Roadmap(props) {
  
  const navigate = useNavigate();
  const location = useLocation(); 
  const loc = location.pathname;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [redirectpage, setRedirectpage] = useState(false);
  const [snackbaropen, setSnackbaropen] = React.useState(false);
  const [severity, setSeverity] = React.useState("info");
  const [snackbarmessage, setSnackbarmessage] = React.useState("Loading...");
  const data = [
    { label: 'Airdrops', value: '25%' },
    { label: 'Farming', value: '17%' },
    { label: 'Initial Sign-up', value: '3%' },
    { label: 'To exchanges', value: '25%' },
    { label: 'Tech and Content Team', value: '2%' },
    { label: 'Crowdwisdom360', value: '25%' },
    { label: 'Founders', value: '3%' },
];

const data1 = [
    { label: 'Starting Token Distribution – Airdrop', date: 'Sep 22, 2024' },
    { label: 'Launching Subscription Service', date: 'Oct 1, 2024' },
    { label: 'Launching Farming', date: 'Oct 10, 2024' },
    { label: 'Bonus Airdrop – 1.5x tokens', date: 'Q1 2025' },
    { label: 'Announcing Exchanges', date: 'Q2 2025' },
    { label: 'Exchange Listing', date: 'Q2 2025' }
];

const menuItems = [
  { name: "Overview", route: "overview" },
  { name: "Creator and Investor", route: "CreatorNInvestor" },
  { name: "How it works?", route: "working" },
  { name: "Tokenomics", route: "tokenomics" },
  { name: "Plan", route: "Plan" },
  { name: "Use Cases", route: "UseCases"}
];

const [selectedSection, setSelectedSection] = useState(menuItems[0].route);

const handlePrevNext = (direction) => {
  const currentIndex = menuItems.findIndex(
    (item) => item.route === selectedSection
  );
  let newIndex = currentIndex;

  if (direction === "prev" && currentIndex > 0) {
    newIndex = currentIndex - 1;
  } else if (direction === "next" && currentIndex < menuItems.length - 1) {
    newIndex = currentIndex + 1;
  }

  setSelectedSection(menuItems[newIndex].route);
};

  const renderSection = () => {
    switch (selectedSection) {
      case 'overview':
        return (
          <div style={{ padding: '5%', backgroundColor: '#f9f9f9', borderRadius: '8px', maxWidth: '100%', boxSizing: 'border-box', fontFamily: "Satoshi, Arial, sans-serif", color: '#333', lineHeight: '1.6' }}>
          <h2 style={{ color: '#2c3e50', borderBottom: '2px solid #16a085', paddingBottom: '10px' }}>Overview</h2>
          <p style={{ marginBottom: '20px' }}>
            Tired of stressing about crypto? We get it. <strong>Crowd Wisdom</strong> simplifies your journey with portfolios and baskets that <strong>consistently outperform the market.</strong> <strong>Here's why you can trust us:</strong>
          </p>
          <ul style={{ marginLeft: '20px', marginBottom: '20px' }}>
            <li><strong>Proven track record:</strong> We've been researching crypto for 6 years and managing portfolios for 3 years.</li>
            <li><strong>Expert team:</strong> Our founders are investment pros, and our tech wizards make the platform user-friendly for everyone.</li>
            <li><strong>Millions strong:</strong> We've already helped 6 million people navigate crypto with our research. Now, we take things a step further with easy investing.</li>
          </ul>
          <p style={{ marginBottom: '20px' }}>
          <strong>Ready to get started?</strong> Visit <a href="https://investing.crowdwisdom.live" style={{ color: '#1877F2',}}>investing.crowdwisdom.live</a> to see our winning portfolios and creators!
          </p>
        </div>        

        );
      case 'CreatorNInvestor':
        return <div style={{ padding: '5%', backgroundColor: '#f9f9f9', borderRadius: '8px', maxWidth: '100%', boxSizing: 'border-box', fontFamily: "Satoshi, Arial, sans-serif", color: '#333', lineHeight: '1.6', marginTop: '30px' }}>
        <h2 style={{ color: '#2c3e50', borderBottom: '2px solid #16a085', paddingBottom: '10px' }}>Creator and Investor</h2>
        <p style={{ marginBottom: '20px', fontFamily:'18px' }}>
          Simplify your crypto journey with Crowdwisdom360.
        </p>
        <h4 style={{ color: '#2c3e50', marginBottom: '10px' }}>Creators:</h4>
        <ul style={{ marginLeft: '20px', marginBottom: '20px' }}>
          <li><strong>Share your expertise:</strong> Showcase your crypto knowledge and build a following.</li>
          <li><strong>Earn rewards:</strong> Get rewarded for your recommendations and engagement.</li>
          <li><strong>Create your own portfolio:</strong> Share your curated list of crypto assets in one place.</li>
        </ul>
        <h4 style={{ color: '#2c3e50', marginBottom: '10px' }}>Investors:</h4>
        <ul style={{ marginLeft: '20px', marginBottom: '20px' }}>
          <li><strong>Discover top creators:</strong> Find trusted influencers and their recommended portfolios.</li>
          <li><strong>Access AI-powered tools:</strong> Use our smart tools to analyze different options.</li>
          <li><strong>Join a community:</strong> Connect with other investors and learn from experts.</li>
        </ul>
        <h4 style={{ color: '#2c3e50', marginBottom: '10px' }}>Everyone benefits:</h4>
        <ul style={{ marginLeft: '20px', marginBottom: '20px' }}>
          <li><strong>Easy-to-use platform:</strong> Navigate seamlessly and find what you need.</li>
          <li><strong>Rewarding experience:</strong> Earn tokens and commissions for your contributions.</li>
          <li><strong>Strong community:</strong> Connect with like-minded investors and creators.</li>
        </ul>
      </div>;
      
      case 'working':
        return <div style={{ padding: '5%', backgroundColor: '#f9f9f9', borderRadius: '8px', maxWidth: '100%', boxSizing: 'border-box', fontFamily: "Satoshi, Arial, sans-serif", color: '#333', lineHeight: '1.6', marginTop: '30px' }}>
        <h2 style={{ color: '#2c3e50', borderBottom: '2px solid #16a085', paddingBottom: '10px' }}>How does the platform work?</h2>
        <p style={{ marginBottom: '20px' }}>
          Invest smarter with Crowd Wisdom.
        </p>
        <h4 style={{ color: '#2c3e50', marginBottom: '10px' }}>For Creators:</h4>
        <ul style={{ marginLeft: '20px', marginBottom: '20px' }}>
          <li><strong>Share your expertise:</strong> Showcase your crypto knowledge and build a following.</li>
          <li><strong>Earn rewards:</strong> Get rewarded for your recommendations and engagement.</li>
          <li><strong>Create your own portfolio:</strong> Share your curated list of crypto assets in one place.</li>
          <li><strong>Promote your portfolio:</strong> Easily share your recommendations on social media.</li>
        </ul>
        <h4 style={{ color: '#2c3e50', marginBottom: '10px' }}>For Investors:</h4>
        <ul style={{ marginLeft: '20px', marginBottom: '20px' }}>
          <li><strong>Discover top portfolios: </strong>Find high-performing portfolios in various categories.</li>
          <li><strong>Analyze your options:</strong> Explore portfolio details, ROI, and creator profiles.</li>
          <li><strong>Build your watchlist:</strong> Curate a personalized list of assets to track.</li>
          <li><strong>Earn rewards:</strong> Get rewarded for participating and engaging on the platform.</li>
        </ul>
        <h4 style={{ color: '#2c3e50', marginBottom: '10px' }}>Everyone benefits:</h4>
        <ul style={{ marginLeft: '20px', marginBottom: '20px' }}>
          <li><strong>Easy-to-use platform:</strong> Navigate seamlessly and find what you need.</li>
          <li><strong>Rewarding experience:</strong> Earn $WISE tokens for your contributions.</li>
          <li><strong>Strong community:</strong> Connect with like-minded investors and creators.</li>
        </ul>
        <div style={{ marginBottom: '20px',fontFamily:'22px' }}>
          Join us today and simplify your crypto journey.
        </div>
      </div>
      

      case 'tokenomics':
        return <div style={{ padding: '5%', backgroundColor: '#f9f9f9', borderRadius: '8px', maxWidth: '100%', boxSizing: 'border-box',fontFamily: "Satoshi, Arial, sans-serif", color: '#333', lineHeight: '1.6', marginTop: '30px' }}>
        <h2 style={{ color: '#2c3e50', borderBottom: '2px solid #16a085', paddingBottom: '10px' }}>Tokenomics of $WISD Coins</h2>
        <p style={{ marginBottom: '20px' }}>
        As a company, we want to allocate a fair share of tokens towards – farming, airdrops and participation.        </p>
        <p style={{ marginBottom: '20px' }}>
          The chart below shows the tokenomics distribution:
        </p>
        <img src='/images/tokenomics.jpeg' alt="Tokenomics Distribution" style={{ width: '100%', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginBottom: '20px' }} />
  
        <div style={{ width: '100%', overflowX: 'auto', display: 'flex', justifyContent: 'center', padding: '20px' }}>
          <table style={{ borderCollapse: 'collapse', width: '100%', maxWidth: '600px', border: '1px solid #ddd' }}>
            <thead>
              <tr>
                <th style={{ backgroundColor: '#333', color: '#fff', padding: '12px 15px', textAlign: 'left', fontWeight: 'bold' }}>
                  Allocation
                </th>
                <th style={{ backgroundColor: '#333', color: '#fff', padding: '12px 15px', textAlign: 'left', fontWeight: 'bold' }}>
                  %
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#e6e6e6' }}>
                  <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                    {row.label}
                  </td>
                  <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                    {row.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
  
        <div>
        <p style={{ marginBottom: '20px', fontWeight: 'bold' }}>Here are the details of each allocation:</p>

<p style={{ marginBottom: '20px' }}>
  <strong>Airdrops:</strong>
  <ul style={{ marginLeft: '20px' }}>
    <li><strong>Monthly rewards:</strong> Enjoy regular airdrops as a token of appreciation.</li>
    <li><strong>Boost engagement:</strong> Airdrops are designed to encourage active participation.</li>
    <li><strong>Allocation: 25%</strong></li>
  </ul>
</p>

<p style={{ marginBottom: '20px' }}>
  <strong>Farming:</strong>
  <ul style={{ marginLeft: '20px' }}>
    <li><strong>Earn tokens for your efforts:</strong> Be rewarded for engaging with the platform.</li>
    <li><strong>Continued rewards:</strong> Farming opportunities will persist even after token listing.</li>
    <li><strong>Allocation: 17%</strong></li>
  </ul>
</p>

<p style={{ marginBottom: '20px' }}>
  <strong>Initial Sign-up:</strong>
  <ul style={{ marginLeft: '20px' }}>
    <li><strong>Early adopter bonus:</strong> Receive 3186 $WISD tokens as a thank you for joining early.</li>
    <li><strong>Limited-time offer: </strong>This bonus is available to the first 10,000 customers.</li>
    <li><strong>Allocation: 3%</strong></li>
  </ul>
</p>

<p style={{ marginBottom: '20px' }}>
  <strong>Exchange Listing:</strong>
  <ul style={{ marginLeft: '20px' }}>
    <li><strong>Strategic allocation:</strong> 25% of tokens are reserved for exchange listing.</li>
    <li><strong>Wide accessibility:</strong> Aiming for mid-to-large size exchanges to benefit token holders.</li>
  </ul>
</p>

<p style={{ marginBottom: '20px' }}>
  <strong>Team Rewards:</strong>
  <ul style={{ marginLeft: '20px' }}>
    <li><strong>Recognition for hard work:</strong> Tech and content team members will receive token allocations.</li>
    <li><strong>Company appreciation:</strong> Their contributions are integral to Crowdwisdom360's success.</li>
    <li><strong>Allocation: 2%</strong></li>
  </ul>
</p>

<p style={{ marginBottom: '20px' }}>
  <strong>Company Reserves:</strong>
  <ul style={{ marginLeft: '20px' }}>
    <li>Strategic allocation: 25% of tokens are reserved for the company's account.</li>
    <li>Financial stability: Funds will be used for working capital and reserves.</li>
  </ul>
</p>

<p style={{ marginBottom: '20px' }}>
  <strong>Founders' Share:</strong>
  <ul style={{ marginLeft: '20px' }}>
    <li><strong>Recognition for leadership:</strong> Founders will receive a combined 3% allocation.</li>
    <li><strong>Full-time commitment: </strong>Founders are actively involved in the company and accessible to the community.</li>
  </ul>
</p>

<p style={{ 
  marginBottom: '20px', 
  fontWeight: 'bold',
  border:'1px solid #1877F2',
  textAlign:'center',
 
  }}>Total Token in Circulation: 1.02 Billion</p>

</div>
      </div>;

      case 'Plan':
        return <div style={{ padding: '5%', backgroundColor: '#f9f9f9', borderRadius: '8px', maxWidth: '100%', boxSizing: 'border-box', fontFamily: "Satoshi, Arial, sans-serif", color: '#333', lineHeight: '1.6', marginTop: '30px' }}>
        <h2 style={{ color: '#2c3e50', borderBottom: '2px solid #16a085', paddingBottom: '10px' }}>Our Plan</h2>
        <div style={{ width: '100%', overflowX: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
          <table style={{ borderCollapse: 'collapse', width: '100%', maxWidth: '600px', border: '1px solid #ddd' }}>
            <thead>
              <tr>
                <th style={{ backgroundColor: '#333', color: '#fff', padding: '12px 15px', textAlign: 'left', fontWeight: 'bold' }}>
                  Milestone
                </th>
                <th style={{ backgroundColor: '#333', color: '#fff', padding: '12px 15px', textAlign: 'left', fontWeight: 'bold' }}>
                  Timeline
                </th>
              </tr>
            </thead>
            <tbody>
              {data1.map((row, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#e6e6e6' }}>
                  <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                    {row.label}
                  </td>
                  <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                    {row.date}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>;

case 'UseCases':
  return (
    <div
      style={{
        padding: '5%',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        maxWidth: '100%',
        boxSizing: 'border-box',
        fontFamily: "Satoshi, Arial, sans-serif",
        color: '#333',
        lineHeight: '1.6',
        marginTop: '30px',
      }}
    >
      <h2 style={{ color: '#2c3e50', borderBottom: '2px solid #16a085', paddingBottom: '10px' }}>
        USE CASES OF $WISD TOKEN
      </h2>
      <p style={{ marginBottom: '20px' }}>
        How $WISD Tokens Power Crowdwisdom360:
      </p>

      <h4 style={{ color: '#2c3e50', marginBottom: '10px' }}>1. Purchase Subscription Services:</h4>
      <ul style={{ marginLeft: '20px', marginBottom: '20px' }}>
        <li><strong>Access premium content:</strong> Use $WISD to subscribe to portfolios and creators.</li>
        <li><strong>Enjoy discounts:</strong> Benefit from token-based discounts on subscription fees.</li>
      </ul>

      <h4 style={{ color: '#2c3e50', marginBottom: '10px' }}>2. Participate in Voting:</h4>
      <ul style={{ marginLeft: '20px', marginBottom: '20px' }}>
        <li><strong>Influence the community: </strong>Vote for your favorite portfolios and creators.</li>
        <li><strong>Shape the platform: </strong>Help determine leaderboard rankings and best practices.</li>
      </ul>

      <h4 style={{ color: '#2c3e50', marginBottom: '10px' }}>3. Access Exclusive Content:</h4>
      <ul style={{ marginLeft: '20px', marginBottom: '20px' }}>
        <li><strong>Discover premium offerings:</strong> Gain access to creators' exclusive content.</li>
        <li><strong>Pay with $WISD: </strong>Use your tokens to unlock premium features.</li>
      </ul>

      <h4 style={{ color: '#2c3e50', marginBottom: '10px' }}>4. Earn Additional Income (Creators):</h4>
      <ul style={{ marginLeft: '20px', marginBottom: '20px' }}>
        <li><strong>Monetize your expertise:</strong> Charge for access to your recommendations.</li>
        <li><strong>Earn $WISD:</strong> Receive direct income and affiliate commissions.</li>
      </ul>
    </div>
  );


      default:
        return <div>Select a section</div>;
    }
  };
  
  
  const closesnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbaropen(false);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className="d-flex flex-column h-100" style={{ overflowY: "hidden" }}>
      <MenuListWhitepaper
        onSelectSection={setSelectedSection}
        selectedSection={selectedSection}
      />
    </div>
  );

  const currentIndex = menuItems.findIndex(
    (item) => item.route === selectedSection
  );
  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );




  const container =
    window !== undefined ? () => window().document.body : undefined;

  

  return (
    <>
    {mediaQueryVar === true? (
    <Box sx={{ display: "flex", flexWrap: "wrap", backgroundColor:"#EDF1F6" }} className="main_account">
      <Helmet>
        <title>Whitepaper</title>
      </Helmet>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <div
          style={{
            backgroundColor: "#EDF1F6",
            padding: "5px",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ flexGrow: 1, padding: "2px" }} >
            <Grid container spacing={2}>
              <Grid item xs={12} align="left">
                
              </Grid>
            </Grid>
          </Box>

          <Box
           
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
           
          >
            
            <Grid container spacing={2}>
             

<Box style ={{
 backgroundColor:"white",
 marginTop:"18px",
 marginLeft:"18px",
 marginRight:"18px",
 padding:"32px",
 borderRadius:"10px",
 width:"100%"
}}>

{renderSection()}

<div
  style={{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  }}
>
  <button
    onClick={() => handlePrevNext("prev")}
    disabled={currentIndex === 0}
    style={{
      padding: "10px 20px",
      fontSize: "16px",
      fontWeight: "600",
      color: currentIndex === 0 ? "#ccc" : "#1877F2",
      backgroundColor: currentIndex === 0 ? "#f0f0f0" : "white",
      border: currentIndex === 0 ? "1px solid #ccc" : "1px solid #1877F2",
      borderRadius: "5px",
      cursor: currentIndex === 0 ? "not-allowed" : "pointer",
      transition: "all 0.3s ease",
      outline: "none",
    }}
  >
    &#8592; {currentIndex > 0 ? menuItems[currentIndex - 1].name : ""}
  </button>
  <button
    onClick={() => handlePrevNext("next")}
    disabled={currentIndex === menuItems.length - 1}
    style={{
      padding: "10px 20px",
      fontSize: "16px",
      fontWeight: "600",
      color: currentIndex === menuItems.length - 1 ? "#ccc" : "#1877F2",
      backgroundColor: currentIndex === menuItems.length - 1 ? "#f0f0f0" : "white",
      border: currentIndex === menuItems.length - 1 ? "1px solid #ccc" : "1px solid #1877F2",
      borderRadius: "5px",
      cursor: currentIndex === menuItems.length - 1 ? "not-allowed" : "pointer",
      transition: "all 0.3s ease",
      outline: "none",
    }}
  >
    {currentIndex < menuItems.length - 1 ? menuItems[currentIndex + 1].name : ""} &#8594;
  </button>
</div>




      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={severity}
      />

 </Box>

            </Grid>
          </Box>
        </div>
        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{snackbarmessage}</Alert>
        </Snackbar>
      </Box>
    </Box>
    ):(
      <Box
sx={{
display: "flex",
flexDirection: "column",
minHeight: "100vh", // Ensures it takes at least the full viewport height
flexGrow: 1, // Allows the Box to grow and fill the available space
overflowY: "auto", 
padding:1

}}
>
<Helmet>
        <title>Whitepaper</title>
</Helmet>
<CssBaseline />

<Box
component="nav"
sx={{
  width: "100%", // Take full width on mobile
}}
aria-label="mailbox folders"
>
<Drawer
  container={container}
  variant="temporary"
  open={mobileOpen}
  onClose={handleDrawerToggle}
  ModalProps={{
    keepMounted: true,
  }}
  sx={{
    "& .MuiDrawer-paper": {
      width: "80%", // Adjust width for mobile
      backgroundColor: "white",
      color: "#727376",
    },
  }}
>
  {drawer}
</Drawer>
</Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: 1,
          paddingBottom: 3,
          
          paddingLeft: 1,
          paddingRight: 0,
          backgroundColor: "white",
          paddingBottom: "2vw",
          marginRight: "6px",
          overflow: "auto", // Allow scrolling
        }}
      >
        <div
          style={{
            backgroundColor: "#FFF",
           
          }}
        >
          <Box sx={{ flexGrow: 1, padding: "2px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} align="left">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "0px",
                    marginBottom: "10px",
                    marginLeft: "5px",
                  }}
                >
                  <Typography
                    variant="h5"
                    noWrap
                    component="div"
                    style={{
                      fontFamily: "Satoshi, Arial, sans-serif",
                      marginTop:"-5px",
                      marginLeft:"-5px",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: "700",
                    }}
                  >
                    Whitepaper
                  </Typography>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{ display: { sm: "none" } }}
                  >
                    <img
                      src="/images/menubar.svg"
                      alt="Menu Icon"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "10px",
                        marginTop: "-20px",
                      }}
                    />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Box>

          <Box
            
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
           
          >
            {renderSection()} 
    
          </Box>

          <div
  style={{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    padding: "8px 16px",
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    marginBottom:"80px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  }}
>
  <button
    onClick={() => handlePrevNext("prev")}
    disabled={currentIndex === 0}
    style={{
      flex: 1,
      padding: "8px 12px",
      fontSize: "14px",
      fontWeight: "600",
      color: currentIndex === 0 ? "#ccc" : "#1877F2",
      backgroundColor: currentIndex === 0 ? "#f0f0f0" : "white",
      border: currentIndex === 0 ? "1px solid #ccc" : "1px solid #1877F2",
      borderRadius: "5px",
      cursor: currentIndex === 0 ? "not-allowed" : "pointer",
      transition: "all 0.3s ease",
      outline: "none",
      textAlign: "center",
      marginRight: "8px",
      whiteSpace: "nowrap", // Ensures text doesn't wrap to the next line
      overflow: "hidden",
      textOverflow: "ellipsis", // Handles overflow gracefully
    }}
  >
    &#8592; {currentIndex > 0 ? menuItems[currentIndex - 1].name : ""}
  </button>
  <button
    onClick={() => handlePrevNext("next")}
    disabled={currentIndex === menuItems.length - 1}
    style={{
      flex: 1,
      padding: "8px 12px",
      fontSize: "14px",
      fontWeight: "600",
      color: currentIndex === menuItems.length - 1 ? "#ccc" : "#1877F2",
      backgroundColor: currentIndex === menuItems.length - 1 ? "#f0f0f0" : "white",
      border: currentIndex === menuItems.length - 1 ? "1px solid #ccc" : "1px solid #1877F2",
      borderRadius: "5px",
      cursor: currentIndex === menuItems.length - 1 ? "not-allowed" : "pointer",
      transition: "all 0.3s ease",
      outline: "none",
      textAlign: "center",
      marginLeft: "8px",
      whiteSpace: "nowrap", // Ensures text doesn't wrap to the next line
      overflow: "hidden",
      textOverflow: "ellipsis", // Handles overflow gracefully
    }}
  >
    {currentIndex < menuItems.length - 1 ? menuItems[currentIndex + 1].name : ""} &#8594;
  </button>
</div>

        </div>
        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{snackbarmessage}</Alert>
        </Snackbar>
      </Box>
      <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "100%",
            zIndex: "100",
          }}
        >

      <BottomNavigation showLabels>
      <BottomNavigationAction
        label="Gallery"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => 
          navigate("/my-portfolio")
        }
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
     <BottomNavigationAction
       label={
        <span style={{ letterSpacing: '0.1em' }}>
        Profile
      </span>
       }
       onClick={() => 
      navigate("/account")
  }
  icon={<img src={loc==='/account'? "/images/dollarS.svg":"/images/dollarU.svg"} width={22} height={22} alt="Icon" style={{marginBottom : "-3px"}} />}
/>
    </BottomNavigation>
        </div>
    </Box>

    

)}
</>


  );
  
 
}
  
export default Roadmap;
