export const updateProfile=(userData)=>{

    console.log("UPDATE API CALL INITIATE");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", userData.token);
    // myHeaders.append("Token",userData.Token);
    console.log(userData)
    var raw = JSON.stringify({
      "user_id": userData.id,
      "full_name": userData.name,
      "portfolio_name":userData.portfolioname,
      "country":userData.country
    });
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(`${process.env.REACT_APP_API_URL}/profileupdate`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));

    
}

export const updateAccount=(userData)=>{

  console.log("UPDATE API CALL INITIATE");

  const myHeaders = new Headers();
  myHeaders.append("Authorization", userData.token);

  const bodyContent = new FormData();
  bodyContent.append("user_id", userData.user_id);
  bodyContent.append("full_name", userData.fullName);
  bodyContent.append("photo", userData.profilePhoto);
  bodyContent.append("about", userData.about);
  bodyContent.append("youtube", userData.youtube);
  bodyContent.append("twitter", userData.twitter);
  bodyContent.append("telegram", userData.telegram);
  bodyContent.append("instagram", userData.instagram);
  bodyContent.append("wallet_address", userData.wallet_address);


    return fetch(`${process.env.REACT_APP_API_URL}/accountupdate`, {
      method: "POST",
      body: bodyContent,
      headers: myHeaders,
    })
    .then((res) => {
      return res.json();
    })
    .catch((err) => console.log(err));
}

export const updateWatchlist=(userData)=>{

  console.log("UPDATE API CALL INITIATE");

  const myHeaders = new Headers();
  myHeaders.append("Authorization", userData.token);

  const bodyContent = new FormData();
  bodyContent.append("user_id", userData.user_id);
  bodyContent.append("portfolio_id", userData.portfolio_id);
  bodyContent.append("action", userData.action);

 
    return fetch(`${process.env.REACT_APP_API_URL}/updateWatchlist`, {
      method: "POST",
      body: bodyContent,
      headers: myHeaders,
    })
    .then((res) => {
      return res.json();
    })
    .catch((err) => console.log(err));
}

export const updatePrivacy=(userData)=>{

  console.log("UPDATE API CALL INITIATE");

  const myHeaders = new Headers();
  myHeaders.append("Authorization", userData.token);

  const bodyContent = new FormData();
  bodyContent.append("user_id", userData.user_id);
  bodyContent.append("portfolio_type_id", userData.portfolio_type_id);
  bodyContent.append("action", userData.action);

 
    return fetch(`${process.env.REACT_APP_API_URL}/updatePrivacy`, {
      method: "POST",
      body: bodyContent,
      headers: myHeaders,
    })
    .then((res) => {
      return res.json();
    })
    .catch((err) => console.log(err));
}

export const storeReferrerData = (referrerId, refereeId,state) => {
  console.log("STORE REFERRER DATA API CALL INITIATE");

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const bodyContent = JSON.stringify({
    referrer_id: referrerId,
    user_id: refereeId,
    state : state
  });

  return fetch(`${process.env.REACT_APP_API_URL}/storeReferrerData`, {
    method: "POST",
    headers: myHeaders,
    body: bodyContent
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      return res.json();
    })
    .then((data) => {
      console.log("STORE REFERRER DATA API SUCCESS", data);
      return data;
    })
    .catch((err) => {
      console.error("STORE REFERRER DATA API FAILED", err);
    });
};
