import "./about.css";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Helmet } from 'react-helmet';
import Typography from "@mui/material/Typography";
import MenuList from "../../includes/menulist.jsx";
import MenuListHome from "../../includes/menuListHome.jsx";
import { useState } from "react";
import { Grid } from "@mui/material";
import { getContent } from "../../apihelper/content";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

import json2mq from "json2mq";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ButtonGroup,BottomNavigation,Paper,Chip } from "@mui/material";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useLocation } from "react-router-dom";
import SidebarMenuList from "../../includes/sidebarMenuList.jsx";
const drawerWidth = 292;

function About(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [aboutdata, setAboutData] = useState("");
  const [loader, setLoader] = useState(false);
  const [userData,setUserData]=useState(undefined)
  React.useEffect(() => {
    const localUserData=localStorage.getItem("cw_portfolio_user")
    setUserData(localUserData);
    getContent("about").then((data) => {
      if (data.code === "200") {
        setAboutData(data.content);
        //console.log(aboutdata);
        setLoader(true);
      } else {
        console.log("Api Call Error");
        // Todo pop up the error
      }
    });
  });
  const location = useLocation(); 
  const loc = location.pathname;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className="d-flex flex-column h-100"style={{overflowY:'hidden'}}>
       <SidebarMenuList/>
    </div>
  );
  
  const container =
    window !== undefined ? () => window().document.body : undefined;

    const navigate = useNavigate();

  const mediaQueryVar = useMediaQuery(
      json2mq({
        minWidth: 900,
      })
    );

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      
      <Helmet>
        <title>Contact Us</title>
</Helmet>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              borderRadius: "",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        
        <Box sx={{ flexGrow: 1, margin: "2px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} align="left">
            <div style={{ 
  display: "flex", 
  alignItems: "center", 
  justifyContent: "space-between", 
  marginTop: "0px", 
  marginBottom: "10px" ,
  marginLeft:"5px"
}}>

                <Typography
                  variant="h5"
                  noWrap
                  component="div"
                  style={{
                   fontFamily: "'Satoshi', Arial, sans-serif",
                    fontSize: "30px",
                    fontStyle: "normal",
                    fontWeight: "600",
                  }}
                >
                  Contact Us
                </Typography>
                <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="end"
    onClick={handleDrawerToggle}
    sx={{ display: { sm: "none" } }}
  >
   <img src='/images/menubar.svg'
 alt="Menu Icon" style={{ width: "24px", height: "24px",marginRight:"10px",marginTop:"-20px" }} />
  </IconButton>
</div>
            </Grid>

            <Grid item xs={12} align="left">
              {/* { loader===false &&
                        <Stack>
                             <Skeleton
                          variant="rounded"
                          width={"100%"}
                          height={60}
                          style={{marginBottom:"10px"}}
                        />
                          <Skeleton
                          variant="rounded"
                          width={"100%"}
                          height={60}
                          style={{marginBottom:"10px"}}
                        />
                          <Skeleton
                          variant="rounded"
                          width={"50%"}
                          height={60}
                          style={{marginBottom:"10px"}}
                        />
                        </Stack>

                        } */}
              {/* <div dangerouslySetInnerHTML={{ __html:aboutdata }} /> */}
              <div>
                <p style={{fontFamily: "'Satoshi', Arial, sans-serif"}}>
                At CrowdWisdom360, we provide expert-backed strategies for optimizing your crypto portfolio. We are here to assist you whether you're new to cryptocurrencies or refining your investment approach.
                </p>
                
                <p style={{fontFamily: "'Satoshi', Arial, sans-serif"}}>
                Have questions, need support, or want to share feedback about our platform? We value your insights.
                </p>
                

                <p style={{fontFamily: "'Satoshi', Arial, sans-serif"}}>
                <b>Email Us</b> - For quick assistance, email us at <b>support@crowdwisdom.live</b>. Our support team is ready to help with any queries you have.
                </p>
                
                <p style={{fontFamily: "'Satoshi', Arial, sans-serif"}}>
                <b>Follow us on Twitter: <a href="https://twitter.com/CrowdWisdom360">@CrowdWisdom360</a></b> for updates and tips on enhancing your crypto portfolio strategy.
                </p>

                <p style={{fontFamily: "'Satoshi', Arial, sans-serif"}}>
                Thank you for choosing CrowdWisdom360 to optimize your crypto investments. We're committed to empowering your investment journey with expert insights and reliable support.
                </p>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {mediaQueryVar === false && (


<div
  style={{
    position: "fixed",
    bottom: "0",
    backgroundColor: "white",
    width: "100%",
    zIndex: "100",
    marginBottom:"0px"
  }}
>
<BottomNavigation showLabels>
  <BottomNavigationAction
        label="Gallery"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => 
          navigate("/my-portfolio")
        }
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
     <BottomNavigationAction
       label="Profile"
       onClick={() => 
      navigate("/account")
  }
  icon={<img src={loc==='/account'? "/images/dollarS.svg":"/images/dollarU.svg"} width={22} height={22} alt="Icon" style={{marginBottom : "-3px"}}/>}
/>
</BottomNavigation>
</div>
)}
      
    </Box>
  );
}

export default About;
