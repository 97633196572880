import "./portfolio.css";
import * as React from "react";
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { Helmet } from 'react-helmet';
import { ButtonGroup } from "@mui/material";
import clipboardCopy from 'clipboard-copy';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuList from "../../includes/menulist.jsx";
import MenuListHome from "../../includes/menuListHome.jsx";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect } from "react";
import { Card, Button, Grid, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { BottomNavigation,Paper,Chip } from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import { getCryporCurrencyList } from "../../apihelper/cryptocurrencylist";
import { getsymbollink } from "../../apihelper/getsymbollink.js";
import CircularProgress from "@mui/material/CircularProgress";
import { getFetchdropdown } from "../../apihelper/cryptocurrencylist.js";
import { getOrderBookData } from "../../apihelper/portfolio.js";
import { addToPortfolio } from "../../apihelper/portfolio.js";
import { useNavigate } from "react-router-dom";
import Portfolioadd from "../addPage/portfolioadd.js";
import SidebarMenuList from "../../includes/sidebarMenuList.jsx";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AddIcon from '@mui/icons-material/Add';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useLocation } from "react-router-dom";
const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));
const drawerWidth = 292;

function OrderBook(props) {
  const [user_role, setuser_role] = useState("");
  const { portfolioId,portfolioName } = useParams();

  const navigate = useNavigate();
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  const [snackbaropen, setSnackbaropen] = React.useState(false);

  const classes = useStyles();
  const [alignment, setAlignment] = React.useState("left");
  const [loader, setLoader] = useState(false);
  const [slugdata, setSlugdata] = useState([]);
  const [index, setIndex] = useState(0);
  const [addMoreLoader, setAddMoreLoader] = useState(false);
  const [filter, setFilter] = useState("");
  const [loaderbutton, setLoaderButton] = useState(true);
  const [roivalue, setRoivalue] = useState("-");
  const [portfoliovalue, setPortfoliovalue] = useState("-");
  const [lastUpdatedTime, setLastUpdatedTiem] = useState("");
  const [portfolioid, setportfolioid] = useState("");
  const [slugpopupdata, setSlugpopupdata] = useState({
    slugname: "",
    slug: "",
    units: 0,
  });
  const [msgAfterLoading, setMsgAfterLoading] = useState("");
  const [unit, setUnit] = useState("");
  const [buySell, setbuySell] = React.useState("");
  const [price, setPrice] = useState("");

  const [selectedDate, handleDateChange] = useState("");
  const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] =
    useState("loading...");
  const [severity, setSeverity] = useState("info");
  const [initialPage, setInitialPage] = useState(false);
  const [localUserData,setLocalUserData]=useState(undefined)
  const [loaderForSymbolLink,setLoaderForSymbolLink]=useState(false);
  const [PortfolioRoivalue,setPortfolioRoivalue]=useState('-');
  const [currentwallet,setcurrentwallet]=useState('-');
  const [nodatafoundmsgtype,setnodatafoundmsgtype]=useState('');
  const location = useLocation(); 
  const loc = location.pathname;
  const handleSymbolLink=(symbolName)=>{
    //console.log(symbolName)
    setLoaderForSymbolLink(true);
    getsymbollink(symbolName).then(res=>{
      if(res.code==='200')
      window.open(res.article_link, '_blank', 'noreferrer');
      // window.location.replace(res.article_link);
      // setLoaderForSymbolLink(false);
      setLoaderForSymbolLink(false);
    })
  }

  const handleOpenDPA = (portfolioId,portfolio_name) => {
    const formattedName = portfolio_name.replace(/\s+/g, '-');
    // e.stopPropagation();
    navigate(`/my-portfolio/${portfolioId}/${encodeURIComponent(formattedName)}/detailed-portfolio-analysis`);

  };

  const handleOpen = (idx) => {
    // setopenindex(idx);const today = new Date();
    fetchDropdownOptions();
    setSlugpopupdata({
      slugname: slugdata[idx].slug,
      slug: slugdata[idx].name,
      units: slugdata[idx].total_unit,
      pricetoday: slugdata[idx].crypto_price,
      logo: slugdata[idx].logo,
    });
    setOpen(true);
  };
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };
  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );
  var rows = [],
    i = 0,
    len = 15;
  while (++i <= len) rows.push(i);
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleCreateNew = () => {
    // router.push(`/${path}`);
    navigate("/cryptocurrencylist", { replace: true });
  };

  useEffect(() => {
    setIndex(0);
    setLoader(false);
   if ((filter.length < 1) & (filter.length >= 1)) {
      setLoader(true);
      setAddMoreLoader(false);
    }
    else{
      //console.log(filter);
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      getOrderBookData({
        slug: filter,
        user_id: userData.user_id,
        portfolio_type_id: portfolioId,
        start_index: 0,
        limit: `${10}`,
        Token: userData.token,
      }).then((res) => {
        if (res.code === "200") {
          //console.log("data got successfully");
          //console.log(res);
          setSlugdata(res.data);
          //console.log(res.data)
          setuser_role(res.user_role);
          if (res.data.length < 10) {
            //console.log("change Loaderbutton");
            setLoaderButton(false);
          } else {
            setLoaderButton(true);
          }
          setLoader(true);
          setAddMoreLoader(false);
        } else {
          setMsgAfterLoading(res.message);
          //console.log("Data fething error");
        }
        // console.log(slugdata)
      });
    }
  }, [filter]);

  useEffect(() => {
    //console.log(100)
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    setLocalUserData(userData)
    getOrderBookData({
      slug: filter,
      portfolio_type_id: portfolioId,
      user_id: userData.user_id,
      start_index: `${index}`,
      limit: `${10}`,
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        //console.log("data got successfully");
        setRoivalue(res.total_profit);
        setPortfoliovalue(res.total_portfolio_value);
        setPortfolioRoivalue(res.roi_average);
        setcurrentwallet(res.current_wallet);
        setnodatafoundmsgtype(res.no_data_found_msgtype);
        setSlugdata(res.data);
        setuser_role(res.user_role);
        setLastUpdatedTiem(res.updated_on);
        setportfolioid(res.portfolio_id)
        //console.log(res.portfolio_id)
        if (res.data.length === 0) {
          setInitialPage(true);
        }
        if (res.data.length < 10) {
          setLoaderButton(false);
        } else {
          setLoaderButton(true);
        }
        setLoader(true);
        setAddMoreLoader(false);
      } else {
        //console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  }, []);
  const fetchDropdownOptions = async () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));


    getFetchdropdown({
      user_id: userData.user_id,
      
      Token:userData.token
    }).then((res) => {
      if (res.code === "200") {
       
          setuser_role(res.user_role);
       } else {
        console.log("Data fething error");
      }
    
    });
   
    
  }

  const loadMore = () => {
    // setLoader(false);
    setAddMoreLoader(true);
    setIndex(index + 10);
    //console.log(index);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    getOrderBookData({
      slug: filter,
      portfolio_type_id: portfolioId,
      user_id: userData.user_id,
      start_index: index + 10,
      limit: `${10}`,
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        //console.log("data got successfully");
        setSlugdata([...slugdata, ...res.data]);
        setuser_role(res.user_role);
        //console.log(res.data);
        if (res.data.length < 10) {
          //console.log("change Loaderbutton");
          setLoaderButton(false);
        } else {
          setLoaderButton(true);
        }
        setLoader(true);
        setAddMoreLoader(false);
      } else {
        //console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };

  const closesnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbaropen(false);
  };
  const handleClick = () => {
    setSnackbaropen(true);
    setOpen(true);
    setSeverity("info");
    // setSnackbarcolor("success");
    // setSnackbarmessage("Data saved successfully");
    setMsgforUpdatePortfolio("loading...");
    //console.log(price, selectedDate, buySell, unit);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    addToPortfolio({
      portfolio_type_id: portfolioId,
      existing_portfolio: 'yes',
      user_id: userData.user_id,
      slug: slugpopupdata.slugname,
      unit: unit,
      acquisition_price: price,
      date_time: selectedDate,
      type: buySell,
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        setOpen(false);
        setSeverity("info");
        //console.log(res.message);
        setMsgforUpdatePortfolio(res.message);
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        setLoader(false);
        getOrderBookData({
          slug: filter,
          portfolio_type_id: portfolioId,
          user_id: userData.user_id,
          start_index: `${index}`,
          limit: `${10}`,
          Token: userData.token,
        }).then((res) => {
          if (res.code === "200") {
            //console.log("data got successfully");
            setSlugdata(res.data);
            setuser_role(res.user_role);
            // console.log(res.data)
            if (res.data.length === 0) {
              setInitialPage(true);
            }
            if (res.data.length < 10) {
              setLoaderButton(false);
            } else {
              setLoaderButton(true);
            }
            setLoader(true);
            setAddMoreLoader(false);
          } else {
            //console.log("Data fething error");
          }
          // console.log(slugdata)
        });
        setUnit("");
        setbuySell("");
        setPrice("");
        handleDateChange("");
        //navigate("/portfolio");
      } else {
        setSeverity("error");
        // setOpen(true)
        setMsgforUpdatePortfolio(res.message);
      }
    });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window1 !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }

  const drawer = (
    <div className="d-flex flex-column h-100" style={{overflowY:'hidden'}}>
      <SidebarMenuList/>
    </div>
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
   
    "& td, & th": {
      border: 0,

    },
  }));
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const handleCopyText = async() => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const textToCopy = `${process.env.REACT_APP_HOME_URL}portfolio/${portfolioid}`;
    await clipboardCopy(textToCopy)
   
      .then(() => {
       // console.log("Text copied:", textToCopy);

        // Set the tooltip to open for a short duration (e.g., 2 seconds)
        setTooltipOpen(true);
        setTimeout(() => {
          setTooltipOpen(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Error copying text:", err);
      });
  };


  const formatDateTime = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    
    // Format the date
    const [year, month, day] = datePart.split("-");
    const monthNames = [
      "January", "February", "March", "April", "May", "June", 
      "July", "August", "September", "October", "November", "December"
    ];
    const monthName = monthNames[parseInt(month, 10) - 1];
  
    let daySuffix = "th";
    if (day % 10 === 1 && day !== "11") {
      daySuffix = "st";
    } else if (day % 10 === 2 && day !== "12") {
      daySuffix = "nd";
    } else if (day % 10 === 3 && day !== "13") {
      daySuffix = "rd";
    }
  
    const formattedDate = `${monthName} ${parseInt(day, 10)}${daySuffix}`;
  
    // Format the time
    const [hour, minute] = timePart.split(":");
    const hourInt = parseInt(hour, 10);
    const ampm = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12;
  
    const formattedTime = `${formattedHour}:${minute} ${ampm}`;
  
    return `${formattedDate}, ${formattedTime}`;
  };

  return (
    
    <>
    {mediaQueryVar === true ? (
    <Box
    sx={{
      flexDirection: mediaQueryVar === false ? "column" : "",
   
      display: 'flex',  height: '100vh' 
      
    }}
    className="maindiv"
  >
    <Helmet>
        <title>Order Book</title>
</Helmet>
      <CssBaseline />
      <div className="windowheader">
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={window.constants.asset_path  +'/images/logo_with_bg.png'}
              width={220}
              height={50}
              alt="CWLOGO"
              style={{ margin: "10px", borderRadius: "5px" }}
            />
          </Toolbar>
        </AppBar>
      </div>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              borderRadius: "0px 10px 10px 0px",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              borderRadius: "0px 10px 10px 0px",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          padding: "2vw",
          backgroundColor: "#EDF1F6",
        }}
      >
       
            <div className="windowheader">
              <Toolbar />
            </div>
            <Button size="small" color="primary"onClick={handleGoBack} edge="start"  aria-label="menu"
            style={{
              borderRadius: "42px",
              padding:"10px 14px",
              backgroundColor:"#FFF",
              border:"1px solid #F0F0F0",
              width:"85px",
              height:"38px",
              marginLeft:"15px",
              marginBottom:"10px"
            }}>
            <img src={window.constants.asset_path + '/images/backarrow.svg'} alt="" /> 
            <span style={{fontSize:"14px",fontWeight:"500",color:"#727272",marginLeft:"8px",textTransform:"none"}}>Back</span>
            </Button>
            <Box sx={{ flexGrow: 1, marginBottom: "15px" }} >
              <Grid
                container
                spacing={2}
                style={{ display: "flex", justifyContent: "space-between",  }}
              >
                <Grid item className="CryptocurrencyListHeader" >
              
                  <Box sx={{marginTop:'10px'}}>
                  
                  <Typography  noWrap  style={{
        fontSize: "13px",
        fontStyle: "normal",
       
        
        
      }}
    
    >
    <div style={{ padding:"0x 10px 0px 10px", color: "black", border: "none", fontWeight: "bold", fontSize: mediaQueryVar === false ? "15px" : "24px",marginLeft:"10px" }}>
    Order Book
    <Button
    variant="outlined"
    style={{
      backgroundColor: "white",
      color: "#1877F2",
      borderColor: "#1877F2",
      borderWidth: "2px",
      borderStyle: "solid",
      borderRadius: "27px",
      textTransform: "none",
      fontFamily: "Satoshi, sans-serif",
      fontSize: "16px",
      fontWeight: "700",
      padding: "5px 20px",
      maxHeight: "40px",
      whiteSpace: "nowrap",
      marginLeft:"20px"
    }}
    onClick={(e) => {                                    
      handleOpenDPA(portfolioId, portfolioName);
  }}
    onMouseDown={(e) => {
      e.currentTarget.style.backgroundColor = "#1877F2";
      e.currentTarget.style.color = "white";
    }}
    onMouseUp={(e) => {
      e.currentTarget.style.backgroundColor = "white";
      e.currentTarget.style.color = "#1877F2";
    }}
  >
    Detailed Portfolio Analysis
  </Button>
  </div>
    </Typography>
    
    </Box>

                </Grid>
                {mediaQueryVar === true && (
                <div
                  style={{
                 
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                    width: mediaQueryVar === false ? "100%" : "",
                    marginLeft: "18px",
                    fontSize: "14px",
                  }}
                >
               
                </div>
                )}
              </Grid>
            </Box>  
             
{initialPage &&   <Box
        component="main"
        sx={{
         
          flexGrow: 1,
          p: 3,
         
        }}
      >

<Grid container spacing={2}  sx={{marginTop:'0px',marginBottom:"9px", marginLeft: mediaQueryVar === false ? "-5px" : "",}}>

</Grid>
        <div style={{borderRadius:"10px",background:"white",padding:"10px"}}>
        <div>
          <h3>Portfolio</h3>
          
        </div>
        <div className="frameParent8">
          <div className="frameParent9" style={{ padding: "10px" }}>
            <img className="frameIcon6" alt="" src={window.constants.asset_path +'/images/frame20.svg'} />
            <div className="youDontHaveContainer">
            {nodatafoundmsgtype === 'zeroholding' ? (
      <strong>
        <h3 className="youDontHave" style={{ fontWeight: "400", color: "black" }}>
        You have  0 Holdings at the Moment
        </h3>
        {/* <h3 style={{ fontWeight: "400", color: "black" }}>0 Holdings at the Moment </h3> */}
      </strong>
    ) : (
      <strong>
      <h3 className="youDontHave" style={{ fontWeight: "400", color: "black" }}>
      Create New 

      </h3>
      <h3 style={{ fontWeight: "400", color: "black" }}>Portfolio </h3>
    </strong>
    )}
              <button
                style={{
                  backgroundColor: "#4460EF",
                  width: "200px",
                  height: "50px",
                  borderRadius: "5px",
                  marginTop: "20px",
                  border: "2px solid #4460EF",
                  color: "white",
                }}
                onClick={handleCreateNew}
              >
             Add Cryptos to this Portfolio
              </button>
            </div>
          </div>
        </div>
        </div>
      </Box>}
        {!initialPage && (
          <>
            <div style={{ border: "1px solid white",  borderRadius: "8px", padding:"32px", margin:"0px 25px 10px 10px", boxShadow: "0 2px 4px rgba(0,0,0,0)", backgroundColor: "white" }}>
            
              <TableContainer style={{ overflowX: "hidden", border: "1px solid #DADEDF", borderRadius: "5px", padding:0 }}>
                <Table
                  className="non_responsiveTable" aria-label="customized table" 
                  style={{ borderCollapse: "collapse" }}
                >
                  <TableHead>
                  <TableRow>
                        
                        <StyledTableCell className="cryptotableviewHeader" style={{ borderRadius: "8px 0px 0px 8px", border: 0, padding: 0, width:"25%" }}>
                          <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "100%", border: "none", background: "white", padding: "12px 12px", margin: "0px 2px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
                            Crypto
                          </button>
                        </StyledTableCell>
                        
                        <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"12%" }}>
                          <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "100%", border: "none", background: "white", padding: "12px 12px", margin: "0px 0px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
                            Units
                          </button>
                        </StyledTableCell>
                        
                        <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"25%" }}>
                          <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "100%", border: "none", background: "white", padding: "12px 12px", margin: "0px 2px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
                            Date
                          </button>
                        </StyledTableCell>
                        
                        <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"18%" }}>
                          <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "100%", border: "none", background: "white", padding: "12px 12px", margin: "0px 0px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
                            Price
                          </button>
                        </StyledTableCell>
                        
                        <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"17%" }}>
                          <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "100%", border: "none", background: "white", padding: "12px 12px", margin: "0px 2px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
                           Buy/Sell
                          </button>
                        </StyledTableCell>
                        
                        
                                            </TableRow>
                  </TableHead>
                  {loader === false && (
                    <TableBody>
                      {rows.map((idx) => (
                        <StyledTableRow key={idx} >
                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                              sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell >
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                          
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  )}
                  {loader && (
                    <TableBody>
                    {slugdata.map((obj, idx) => (
                      <StyledTableRow key={idx} style={{ backgroundColor: idx % 2 === 0 ? "white" : "#F0F0F0"}}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            paddingLeft: "16px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <div>
                              <img width={39.77} height={38} style={{borderRadius: "19px", border:"2px solid black"}} alt="" src={obj.logo} />
                            </div>
                            <div className="btcParent">
                              {obj["article_link"] && (
                                <a href={obj.article_link}>
                                  <div>{obj.slug}</div>
                                </a>
                              )}
                              {!obj["article_link"] && <div className="article-link">{obj.slug}</div>}
                            </div>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            paddingLeft: "16px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          {obj.total_unit}
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            paddingLeft: "16px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          <div>
                          {formatDateTime(obj.last_updated)}
  </div>
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            paddingLeft: "16px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          {obj.acquisitionPrice}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            paddingLeft: "16px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                         <div>
                             <div style={{ display: "flex", flexDirection: "column" }}>
                                {obj.type === 'sell' ? (
                                   <button disabled style={{ alignItems: 'center', fontWeight:"bold", padding:"5px",borderRadius: "5px", backgroundColor: '#FFE3E3', color:'#FF4141', border: '2px solid #FF4141', width:"50%" }}>Sell</button>
                                ) : (
                               <button disabled style={{ alignItems: 'center',fontWeight:"bold",padding:"5px", borderRadius: "5px", backgroundColor: '#DFFFF0', color:'#199F5F', border: '2px solid #199F5F', width:"50%" }}>Buy</button>
                                )}
                             </div>
                         </div>

                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                  
                  )}
                </Table>
                {slugdata.length === 0 && loader === true && (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <h4>No Data Found</h4>
                  </div>
                )}
              </TableContainer>
              {loader && loaderbutton && (
                <div className="loadingButton">
                  <LoadingButton
                    loading={addMoreLoader}
                    loadingPosition="center"
                    variant="outlined"
                    style={{
                      marginBottom:"60px",
                      maxWidth: "360px",
                      width: "80vw",
                      backgroundColor: "rgba(67, 97, 238, 0.15)",
                      borderRadius: "6px",
                      border: "none",
                      textTransform: "none",
                      borderStyle: "solid",
                      borderColor: "rgba(67, 97, 238, 0.15)",
                      borderWidth: "1px",
                      color: "#4361ee",
                      width: mediaQueryVar === false ? "80vw" : "180px",
                    }}
                    onClick={() => {
                      loadMore();
                    }}
                  >
                    <span>Load More</span>
                  </LoadingButton>
                </div>
              )}
            </div>
            </>
        )
      }
      
        {loaderForSymbolLink===true &&
         <div >
              <CircularProgress style={{position:"fixed",top:"40vh",left:"45vw"}}/>
          </div> 
          }
      </Box>
    </Box>
    ) : (
      <Box
      sx={{
        display: "flex",
        height: '100vh' 
      }}
      className="maindiv"
    >  
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
             
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
             
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        
        component="main"
        sx={{
      flexGrow: 1,
      p: 2,
      backgroundColor: "white",
      paddingBottom: "2vw",
      minHeight: "100vh",
      overflowY:"auto"
        }}
      >
     
           
  <div style={{ 
  display: "flex", 
  alignItems: "center", 
  justifyContent: "space-between", 
  marginTop: "0px", 
  marginBottom: "10px" ,
  marginLeft:"5px",
  
}}>
   <Box sx={{ flexGrow: 1, marginBottom: "15px", }}>
      <Grid
        container
        spacing={2}
        style={{ display: "flex", justifyContent: "space-between" 
          
        }}
      >
        <Grid item className="CryptocurrencyListHeader">
          <Box sx={{ marginTop: "10px" }}>
            <Typography
              noWrap
              style={{
                fontFamily: "Satoshi, sans-serif",
                fontSize: "13px",
                fontStyle: "normal",
              }}
            >
              <div style={{display:"flex"}}>
              <div><img src={`${window.constants.asset_path}/images/order-book.svg`} style={{margin:"5px 8px 8px 8px"}}></img></div>
              <div
                style={{
                 
                  color: "#1877F2",
                  border: "none",
                  fontWeight: "700",
                  fontSize: "20px",
                  marginTop:"2%"
                }}
              >
                Order Book
              </div>
              </div>
              <Button
    variant="outlined"
    style={{
      backgroundColor: "white",
      color: "#1877F2",
      borderColor: "#1877F2",
      borderWidth: "2px",
      borderStyle: "solid",
      borderRadius: "27px",
      textTransform: "none",
      fontFamily: "Satoshi, sans-serif",
      fontSize: "16px",
      fontWeight: "700",
      padding: "5px 20px",
      maxHeight: "40px",
      whiteSpace: "nowrap",
      marginLeft:"5px",
      marginTop:"20px",
    }}
    onClick={(e) => {                                    
      handleOpenDPA(portfolioId, portfolioName);
  }}
    onMouseDown={(e) => {
      e.currentTarget.style.backgroundColor = "#1877F2";
      e.currentTarget.style.color = "white";
    }}
    onMouseUp={(e) => {
      e.currentTarget.style.backgroundColor = "white";
      e.currentTarget.style.color = "#1877F2";
    }}
  >
    Detailed Portfolio Analysis
  </Button>
            </Typography>
          </Box>
        </Grid>
        {mediaQueryVar === true && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              width: mediaQueryVar === false ? "100%" : "",
              marginLeft: "10px",
              fontSize: "14px",
            }}
          ></div>
        )}
      </Grid>
    </Box>

  
  <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="end"
    onClick={handleDrawerToggle}
    sx={{ display: { sm: "none" } }}
  >
   <img src={window.constants.asset_path  + '/images/menubar.svg'}
 alt="Menu Icon" style={{ width: "24px", height: "24px",marginRight:"10px",marginTop:"-20px" }} />
  </IconButton>
</div>

    {initialPage && (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          overflowY:"auto"
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "0px",
            marginBottom: "9px",
            marginLeft: mediaQueryVar === false ? "-5px" : "",
          }}
        ></Grid>
        <div style={{ borderRadius: "10px", background: "white", padding: "10px" }}>
          <div>
            <h3>Portfolio</h3>
          </div>
          <div className="frameParent8">
            <div className="frameParent9" style={{ padding: "10px" }}>
              <img
                className="frameIcon6"
                alt=""
                src={window.constants.asset_path + "/images/frame20.svg"}
              />
              <div className="youDontHaveContainer">
                {nodatafoundmsgtype === "zeroholding" ? (
                  <strong>
                    <h3
                      className="youDontHave"
                      style={{ fontWeight: "400", color: "black" }}
                    >
                      You have 0 Holdings at the Moment
                    </h3>
                    {/* <h3 style={{ fontWeight: "400", color: "black" }}>0 Holdings at the Moment </h3> */}
                  </strong>
                ) : (
                  <strong>
                    <h3
                      className="youDontHave"
                      style={{ fontWeight: "400", color: "black" }}
                    >
                      Create New
                    </h3>
                    <h3 style={{ fontWeight: "400", color: "black" }}>Portfolio</h3>
                  </strong>
                )}
                <button
                  style={{
                    backgroundColor: "#4460EF",
                    width: "200px",
                    height: "50px",
                    borderRadius: "5px",
                    marginTop: "20px",
                    border: "2px solid #4460EF",
                    color: "white",
                  }}
                  onClick={handleCreateNew}
                >
                  Add Cryptos to this Portfolio
                </button>
              </div>
            </div>
          </div>
        </div>
      </Box>
    )}
    {!initialPage && (
      <>
        {loader === false && (
          <Box sx={{ padding: "16px", backgroundColor: "#FFF", borderRadius: "8px", margin: "16px 0" }}>
            {rows.map((idx) => (
              <Box
                key={idx}
                sx={{
                  padding: "16px",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  marginBottom: "16px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                  <Skeleton
                    sx={{ marginRight: "16px", borderRadius: "8px" }}
                    variant="rectangular"
                    width={40}
                    height={40}
                  />
                  <Skeleton variant="text" width={200} height={30} />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Skeleton variant="text" width="30%" height={30} />
                  <Skeleton variant="text" width="30%" height={30} />
                  <Skeleton variant="text" width="30%" height={30} />
                </Box>
              </Box>
            ))}
          </Box>
        )}
        {loader && (
          <Box sx={{
            marginBottom:"70px",
            overflowY:"auto",
            marginTop:"-20px",
            "&::-webkit-scrollbar": {
      display: "none",
    },
    // Hides scrollbar for Firefox
    scrollbarWidth: "none", }}>
            {slugdata.map((obj, idx) => (
              <Box
                key={idx}
                style={{
                  padding: "0",
                  backgroundColor: "white",
                  borderRadius: "6px",
                  margin: "15px 10px",
                  border: "1px solid #DADEDF",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "0" }}>
                  <img
                    src={obj.logo}
                    alt={obj.slug}
                    width={40}
                    height={40}
                    style={{ borderRadius: "19px", marginRight: "16px", border:"2px solid #000", width:"40px", height:"40px", margin : "8px" }}
                  />
                  <Typography variant="h6" style={{fontSize:"18px", fontWeight:"500"}}>{obj.slug}</Typography>
                </Box>
                <Box sx={{background:"#EBE9EB", borderRadius:"6px", padding: "2px 10px 2px 10px"}}>
                  <div style={{ display: "flex", justifyContent: "space-between", margin:"3px" }}>
                    <span style={{textAlign: "left", fontSize:"13px", color:"#76787A", fontWeight:"500"}} variant="subtitle1">Units:</span>
                    <span style={{textAlign: "right",fontWeight:"500", fontSize:"14px"}} variant="body1">{obj.total_unit}</span>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", backgroundColor:"white", borderRadius:"4px", paddingLeft:"3px", paddingRight:"3px"}}>
                    <span variant="subtitle1" style={{textAlign: "left", fontSize:"13px", color:"#76787A", fontWeight:"500"}}>Date:</span>
                    <span variant="body1" style={{textAlign: "right", fontWeight:"500", fontSize:"14px"}}>
                    {formatDateTime(obj.last_updated)}
                    </span>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", margin:"4px"}}>
                    <span variant="subtitle1" style={{textAlign: "left", fontSize:"13px", color:"#76787A", fontWeight:"500"}}>Aquisition price:</span>
                    <span variant="body1" style={{textAlign: "right", fontWeight:"500", fontSize:"14px"}}>{obj.acquisitionPrice}</span>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", backgroundColor:"white", borderRadius:"4px", margin:"5px 1px 5px 1px", padding:"5px 4px" }}>
                    <span variant="subtitle1" style={{textAlign: "left", fontSize:"13px", color:"#76787A", fontWeight:"500"}}>Buy/Sell:</span>
                    {obj.type === 'sell' ? (
                                   <button disabled style={{ alignItems: 'center', fontWeight:"bold", borderRadius: "5px", backgroundColor: '#FFE3E3', color:'#FF4141', border: '1px solid #FF4141', width:"30%", height:"18%", padding:"2px" }}>Sell</button>
                                ) : (
                               <button disabled style={{ alignItems: 'center',fontWeight:"bold", borderRadius: "5px", backgroundColor: '#DFFFF0', color:'#199F5F', border: '1px solid #199F5F', width:"30%", height:"18%", padding:"2px"}}>Buy</button>
                                )}
                  </div>
                </Box>
              </Box>
            ))}
            {loader && loaderbutton && (
                <div className="loadingButton">
                  <LoadingButton
                    loading={addMoreLoader}
                    loadingPosition="center"
                    variant="outlined"
                    style={{
                      backgroundColor: "rgba(67, 97, 238, 0.15)",
                      borderRadius: "6px",
                      border: "none",
                      textTransform: "none",
                      borderStyle: "solid",
                      borderColor: "rgba(67, 97, 238, 0.15)",
                      borderWidth: "1px",
                      color: "#4361ee",
                      width: mediaQueryVar === false ? "40vw" : "180px",
                    }}
                    onClick={() => {
                      loadMore();
                    }}
                  >
                    <span>Load More</span>
                  </LoadingButton>
                </div>
              )}
          </Box>
        )}
      </>
    )}
  </Box>
  {mediaQueryVar === false && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "100%",
            zIndex: "100",
          }}
        >
          <BottomNavigation showLabels>
  <BottomNavigationAction
        label="Gallery"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => 
          navigate("/my-portfolio")
        }
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
     <BottomNavigationAction
       label="Profile"
       onClick={() => 
      navigate("/account")
  }
  icon={<img src={loc==='/account'? "/images/dollarS.svg":"/images/dollarU.svg"} width={22} height={22} alt="Icon" style={{marginBottom : "-3px"}}/>}
/>
</BottomNavigation>
        </div>
      )}
</Box>

    )}
  </>
  );
}

export default OrderBook;