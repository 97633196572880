import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import './CryptoPage.css'; // Assuming you have a CSS file for styling
import { fontSize } from '@mui/system';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ButtonGroup,BottomNavigation,Paper,Chip, Button } from "@mui/material";
import SidebarMenuList from "../includes/sidebarMenuList.jsx";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid, useStepContext } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import { useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';

const drawerWidth = 292;

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 900,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
}));

const CryptoPage = (props) => {
  const { window1 } = props;
  const { crypto } = useParams();
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortType, setSortType] = useState('fd_asc');
  const [sortDropdownVisible, setSortDropdownVisible] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [slug, setSlug] = useState(false);
  const [sevenDays, setSevenDays] = useState(false);
  const [thirtyDays, setThirtyDays] = useState(false);
  const [yearDays, setYearDays] = useState(false);
  const [buyPercent, setBuyPercent] = useState(false);
  const [holdings, setHoldings] = useState(false);
  const [symbolLogo, setSymbolLogo] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(false);
  const [rowsToShow, setRowsToShow] = useState(7);
  const [selectedSort, setSelectedSort] = useState('');
  const sortRef = useRef();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation(); 
  const loc = location.pathname;
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );

  const mediaQueryVar1 = useMediaQuery(
    json2mq({
      minWidth: 400,
    })
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
   
    "& td, & th": {
      border: 0,

    },
  }));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
};

const drawer = (
    <div className='d-flex flex-column h-100' style={{overflowY:'hidden'}}>
<SidebarMenuList/>
    </div>
);


const container = window1 !== undefined ? () => window1().document.body : undefined;

  useEffect(() => {
    const fetchData = async () => {
      console.log(crypto+'hello');
      try {
        const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/showForecasterFutureDataInvesting', { // Replace with your actual API endpoint
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            searchVal: crypto, // Adjust if necessary
            sortType: sortType
          }),
        });

        const result = await response.json();
        console.log(result);

        if (result.code === '200') {
          setLoader(true);
          setSlug(result.symbol);
          setCurrentPrice(result.current_price);
          setSymbolLogo(result.symbol_logo);
          setSevenDays(result.last_7_days);
          setThirtyDays(result.last_30_days);
          setYearDays(result.last_365_days);
          setBuyPercent(result.buy_percent);
          setHoldings(result.holdings);
          setData(result.response);
          console.log({slug});
        } else {
          setError(result.message);
        }
      } catch (err) {
        setError('An error occurred while fetching data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [crypto, sortType]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setSortDropdownVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = (index) => {
    setDropdownVisible(dropdownVisible === index ? null : index);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (sortType) => {
    setSortType(sortType);
    setSortDropdownVisible(false);
    setSelectedSort(sortType);
  };

  const openModal = async (data) => {
    if (data.accuracy.toLowerCase() !== 'pending') {
      try {
        const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/showForecasterPastData', { // Replace with your actual API endpoint
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            searchVal: crypto,
            forecasterName: data.forecaster_name,
          }),
        });
  
        const result = await response.json();
        console.log(result);
  
        if (result.code === '200') {
          setModalData(result.response);
          setModalIsOpen(true);
        } else {
          setError(result.message);
        }
      } catch (err) {
        setError('An error occurred while fetching data.');
      }
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalData(null);
  };

  const filteredData = data.filter(forecaster =>
    forecaster.forecaster_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleLoadMore = () => {
    setRowsToShow(rowsToShow + 7);
  };

  const parseDate = (dateStr) => {
  const [month, day, year] = dateStr.split(' ');
  const monthMap = {
    Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
    Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
  };
  const formattedDate = `${year}-${monthMap[month]}-${day.padStart(2, '0')}`;
  return new Date(formattedDate);
};

const compareValues = (val1, val2, order = 'asc') => {
  if (val1 === 'Pending') return 1;  // Ensure 'Pending' is always last
  if (val2 === 'Pending') return -1; // Ensure 'Pending' is always last
  if (order === 'asc') return val1 > val2 ? 1 : val1 < val2 ? -1 : 0;
  return val1 < val2 ? 1 : val1 > val2 ? -1 : 0;
};

const sortedData = [...filteredData].sort((a, b) => {
  switch (sortType) {
    case 'fd_asc':
      return parseDate(a.target_date) - parseDate(b.target_date);
    case 'fd_desc':
      return parseDate(b.target_date) - parseDate(a.target_date);
    case 'ac_asc':
      return compareValues(a.accuracy, b.accuracy, 'asc');
    case 'ac_desc':
      return compareValues(a.accuracy, b.accuracy, 'desc');
    default:
      return 0;
  }
});


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="crypto-page">
       <Helmet>
        <title>{crypto}</title>
</Helmet>
      <Grid item xs={12} align="left">
    <div style={{ 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "space-between", // Changed from space-between to flex-end
        marginTop: "0px", 
        marginBottom: "10px",
        marginLeft: "5px"
    }}>
    {mediaQueryVar===false ? (
        <img src={window.constants.asset_path + '/images/cwbeta.png'} alt="Logo" className="button-logo" />
    ) : (null)}
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
        >
            <img 
                src='/images/menubar.svg'
                alt="Menu Icon" 
                style={{ 
                    width: "24px", 
                    height: "24px",
                    marginRight: "10px",
                    marginTop: "10px" 
                }} 
            />
        </IconButton>
    </div>
</Grid>
<Drawer
    container={container}
    variant="temporary"
    open={mobileOpen}
    onClose={handleDrawerToggle}
    ModalProps={{
        keepMounted: true, // Better open performance on mobile.
    }}
    sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "400", backgroundColor: 'white', color: '#727376' },
    }}
>
    {drawer}
</Drawer>
{mediaQueryVar ? ( null ) : (
<Button onClick={() => navigate(`../predict-now`)} variant="contained" color="primary" style={{ color: "white",
    background: '#1877F2', textTransform: "none", width: 'auto', height: "5.5%", marginBottom: '20px', fontWeight: '500', fontSize: '15.575px', borderRadius: "25px" 
    ,marginTop: mediaQueryVar===true ? "" : "3%", marginLeft: mediaQueryVar===true ? "1.5%" : ""
    }}>
    Predict and Win
  </Button>
)}
      {mediaQueryVar ? (
  
  null
) : (
  
  !loader ? (
    <Box sx={{ padding: "16px", backgroundColor: "#FFF", borderRadius: "8px", margin: "16px 0" }}>
      <Box sx={{ padding: "16px", backgroundColor: "#F9F9F9", borderRadius: "8px", marginBottom: "16px" }}>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
          <Skeleton sx={{ marginRight: "16px", borderRadius: "8px" }} variant="rectangular" width={40} height={40} />
          <Skeleton variant="text" width={200} height={30} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Skeleton variant="text" width="30%" height={30} />
          <Skeleton variant="text" width="30%" height={30} />
          <Skeleton variant="text" width="30%" height={30} />
        </Box>
      </Box>
    </Box>
  ) : (
    <Box 
      sx={{ 
        marginBottom: "70px", 
        overflowY: "auto", 
        "&::-webkit-scrollbar": { display: "none" }, 
        scrollbarWidth: "none" 
      }}
    >
      <Box 
        sx={{ 
          padding: "0", 
          backgroundColor: "white", 
          borderRadius: "6px", 
          marginTop: "15px", 
          marginBottom: "10px", 
          border: "1px solid #DADEDF", 
          marginLeft: "3px", 
          marginRight: "5px" 
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "0"}}>
          <img 
            alt="" 
            src={symbolLogo} 
            width={40} 
            height={40} 
            style={{ 
              borderRadius: "19px", 
              marginRight: "16px", 
              border: "2px solid #000", 
              width: "40px", 
              height: "40px", 
              margin: "8px" 
            }} 
          />
          <Typography variant="h6" style={{ fontSize: "18px", fontWeight: "500" }}>
            {slug}
          </Typography>
          <Typography variant="h6" style={{ fontSize: "16px", fontWeight: "500", float:"right", marginLeft: "auto", marginRight:"2px" }}>
          ${currentPrice}
          </Typography>
        </Box>
        <Box sx={{ background: "#EBE9EB", borderRadius: "6px", padding: "10px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "0px", borderBottom: "2px #DFDFDF solid" }}>
            <Box sx={{ flex: 1, marginRight: "8px" }}>
              <div style={{ display: "flex", margin: "4px", marginTop: "-5px" }}>
                <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500", marginRight: "10px" }}>Last 7 Days:</span>
                <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{sevenDays}</span>
              </div>
            </Box>
            <Box sx={{ flex: 1 }}>
              <div style={{ display: "flex", justifyContent: "space-between", margin: "4px", marginTop: "-5px" }}>
                <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500", marginLeft: "20px" }}>Last 30 Days:</span>
                <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{thirtyDays}</span>
              </div>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", paddingBottom: "10px", marginBottom: "0px", borderBottom: "2px #DFDFDF solid" }}>
            <Box sx={{ flex: 1, marginRight: "8px" }}>
              <div style={{ display: "flex", margin: "4px", marginBottom: "-5px" }}>
                <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500", marginRight: "10px" }}>Last 12 Months:</span>
                <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{yearDays}</span>
              </div>
            </Box>
            <Box sx={{ flex: 1 }}>
              <div style={{ display: "flex", justifyContent: "space-between", margin: "4px", marginBottom: "-5px" }}>
                <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500", marginLeft: "20px" }}>Buy Intent</span>
                <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{buyPercent}</span>
              </div>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ flex: 1, marginRight: "8px" }}>
              <div style={{ display: "flex", margin: "4px", marginBottom: "-5px" }}>
                <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500", marginRight: "10px" }}>Best Portfolios:</span>
                <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}
                className='clickable'
                onClick={() => navigate(`/crypto/${crypto}/portfolios`)}
                >{holdings}</span>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
)}

{mediaQueryVar ? ( null) : (

      <div className="search-sort-row">
        <input
          type="text"
          placeholder="Search Forecaster"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div className="sort-by" ref={sortRef}>
          <div onClick={() => setSortDropdownVisible(!sortDropdownVisible)}>
          Sort By <span style={{fontSize:"12px"}}>{sortDropdownVisible ? '▲' : '▼'}</span>
          </div>
          {sortDropdownVisible && (
            <div className="sort-dropdown">
            <div onClick={() => handleSortChange('fd_asc')}>
              {selectedSort === 'fd_asc' && '✔️'} Forecast date ascending
            </div>
            <div onClick={() => handleSortChange('fd_desc')}>
              {selectedSort === 'fd_desc' && '✔️'} Forecast date descending
            </div>
            <div onClick={() => handleSortChange('ac_desc')}>
              {selectedSort === 'ac_desc' && '✔️'} Accuracy highest
            </div>
            <div onClick={() => handleSortChange('ac_asc')}>
              {selectedSort === 'ac_asc' && '✔️'} Accuracy lowest
            </div>
          </div>
          )}
        </div>
      </div>
)}
      {mediaQueryVar ? (
        
        <div className="layout">
          <div className>
          <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}

                    sx={{

                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376' },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{

                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376'},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            </div>
            
        <div className="content">

        <Button onClick={() => navigate(`../predict-now`)} variant="contained" color="primary" style={{ color: "white",
    background: '#1877F2', textTransform: "none", width: 'auto', height: "50px", marginBottom: '20px', fontWeight: '500', fontSize: '15.575px', borderRadius: "25px" 
    ,marginTop: mediaQueryVar===true ? "" : "3%", marginLeft: mediaQueryVar===true ? "1.5%" : ""
    }}>
    Predict and Win
  </Button>
        <TableContainer 
    style={{ 
      width: "96%", 
      overflowX: "hidden", 
      border: "1px solid #DADEDF", 
      borderRadius: "5px", 
      padding: 0, 
      marginTop: "2%", 
      marginBottom: "8%" ,
      marginLeft:"2%",
    }}
  >
    <Table className="non_responsiveTable" aria-label="customized table" style={{ borderCollapse: "collapse"}}>
      <TableHead>
        <TableRow>
          <StyledTableCell 
            className="cryptotableviewHeader" 
            style={{ 
              borderRadius: "8px 0 0 8px", 
              border: 0, 
              padding: 0, 
              width: "25%" 
            }}
          >
            <button 
              disabled 
              style={{ 
                textAlign: "left", 
                color: "#727376", 
                fontSize: "16px", 
                width: "100%", 
                height: "100%", 
                border: "none", 
                background: "white", 
                padding: "12px 12px", 
                margin: "0px 2px 10px 2px", 
                boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" 
              }}
            >
              Crypto
            </button>
          </StyledTableCell>
          <StyledTableCell 
            className="cryptotableviewHeader" 
            style={{ padding: 0, width: "auto" }}
          >
            <button 
              disabled 
              style={{ 
                textAlign: "center", 
                color: "#727376", 
                fontSize: "16px", 
                width: "100%", 
                height: "100%", 
                border: "none", 
                background: "white", 
                padding: "12px 12px", 
                margin: "0px 0px 10px 2px", 
                boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" 
              }}
            >
              Last 7 Days
            </button>
          </StyledTableCell>
          <StyledTableCell 
            className="cryptotableviewHeader" 
            style={{ padding: 0, width: "auto" }}
          >
            <button 
              disabled 
              style={{ 
                textAlign: "center", 
                color: "#727376", 
                fontSize: "16px", 
                width: "100%", 
                height: "100%", 
                border: "none", 
                background: "white", 
                padding: "12px 12px", 
                margin: "0px 0px 10px 2px", 
                boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" 
              }}
            >
              Last 30 Days
            </button>
          </StyledTableCell>
          <StyledTableCell 
            className="cryptotableviewHeader" 
            style={{ padding: 0, width: "auto" }}
          >
            <button 
              disabled 
              style={{ 
                textAlign: "center", 
                color: "#727376", 
                fontSize: "16px", 
                width: "100%", 
                height: "100%", 
                border: "none", 
                background: "white", 
                padding: "12px 12px", 
                margin: "0px 0px 10px 2px", 
                boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" 
              }}
            >
              Last 12 Months
            </button>
          </StyledTableCell>
          <StyledTableCell 
            className="cryptotableviewHeader" 
            style={{ padding: 0, width: "auto" }}
          >
            <button 
              disabled 
              style={{ 
                textAlign: "center", 
                color: "#727376", 
                fontSize: "16px", 
                width: "100%", 
                height: "100%", 
                border: "none", 
                background: "white", 
                padding: "12px 12px", 
                margin: "0px 0px 10px 2px", 
                boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" 
              }}
            >
              Buy Intent
            </button>
          </StyledTableCell>
          <StyledTableCell 
            className="cryptotableviewHeader" 
            style={{ padding: 0, width: "auto" }}
          >
            <button 
              disabled 
              style={{ 
                textAlign: "center", 
                color: "#727376", 
                fontSize: "16px", 
                width: "100%", 
                height: "100%", 
                border: "none", 
                background: "white", 
                padding: "12px 12px", 
                margin: "0px 0px 10px 2px", 
                boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" 
              }}
            >
              Best Portfolios
            </button>
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loader === false ? (
          <StyledTableRow>
            {[...Array(6)].map((_, index) => (
              <StyledTableCell key={index}>
                <Stack>
                  <Skeleton sx={{ marginTop: '10px' }} variant="rounded" height={40} />
                </Stack>
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ) : (
          <StyledTableRow style={{ backgroundColor: "white" }}>
            <StyledTableCell
              component="th"
              scope="row"
              style={{
                borderBottom: "none",
                color: "#000",
                fontSize: "16px",
                fontWeight: "500",
                paddingLeft: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
                borderRight: "1px solid #DADEDF",
                width: "auto"
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <img width={39.77} height={38} style={{ borderRadius: "19px", border: "2px solid black" }} alt="" src={symbolLogo} />
                </div>
                <div className="btcParent">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap:"30%" }}>
                  <div>{slug}</div>
                  <span style={{ textAlign: 'right'}}>${currentPrice}</span>
                </div>
                </div>
              </div>
            </StyledTableCell>
            <StyledTableCell
              className="one"
              style={{
                borderBottom: "none",
                color: "#000",
                fontSize: "16px",
                fontWeight: "500",
                textAlign: "center",
                paddingTop: "8px",
                paddingBottom: "8px",
                borderRight: "1px solid #DADEDF",
                width: "239px"
              }}
            >
              {sevenDays}
            </StyledTableCell>
            <StyledTableCell
              className="one"
              style={{
                borderBottom: "none",
                color: "#000",
                fontSize: "16px",
                fontWeight: "500",
                textAlign: "center",
                paddingTop: "8px",
                paddingBottom: "8px",
                borderRight: "1px solid #DADEDF",
                width: "239px"
              }}
            >
              {thirtyDays}
            </StyledTableCell>
            <StyledTableCell
              className="one"
              style={{
                borderBottom: "none",
                color: "#000",
                fontSize: "16px",
                fontWeight: "500",
                textAlign: "center",
                paddingTop: "8px",
                paddingBottom: "8px",
                borderRight: "1px solid #DADEDF",
                width: "239px"
              }}
            >
              {yearDays}
            </StyledTableCell>
            <StyledTableCell
              className="one"
              style={{
                borderBottom: "none",
                color: "#000",
                fontSize: "16px",
                fontWeight: "500",
                textAlign: "center",
                paddingTop: "8px",
                paddingBottom: "8px",
                borderRight: "1px solid #DADEDF",
                width: "239px"
              }}
            >
              {buyPercent}
            </StyledTableCell>
            <StyledTableCell
              className="clickable"
              style={{
                borderBottom: "none",
                color: "green",
                fontSize: "16px",
                fontWeight: "500",
                textAlign: "center",
                paddingTop: "8px",
                paddingBottom: "8px",
                borderRight: "1px solid #DADEDF",
                width: "239px"
              }}
              onClick={() => navigate(`/crypto/${crypto}/portfolios`)}
            >
              {holdings}
            </StyledTableCell>
          </StyledTableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
        <div className="search-sort-row">
      <input
        type="text"
        placeholder="Search Forecaster"
        className="search-input"
        style={{  padding: "10px",
          margin: "15px"}}
        onChange={handleSearchChange}
      />
      <div className="sort-by" ref={sortRef}>
        <div onClick={() => setSortDropdownVisible(!sortDropdownVisible)}>
          Sort By <span style={{ fontSize: "12px" }}>{sortDropdownVisible ? '▲' : '▼'}</span>
        </div>
        {sortDropdownVisible && (
          <div className="sort-dropdown">
            <div onClick={() => handleSortChange('fd_asc')}>
              {selectedSort === 'fd_asc' && '✔️'} Forecast date ascending
            </div>
            <div onClick={() => handleSortChange('fd_desc')}>
              {selectedSort === 'fd_desc' && '✔️'} Forecast date descending
            </div>
            <div onClick={() => handleSortChange('ac_desc')}>
              {selectedSort === 'ac_desc' && '✔️'} Accuracy highest
            </div>
            <div onClick={() => handleSortChange('ac_asc')}>
              {selectedSort === 'ac_asc' && '✔️'} Accuracy lowest
            </div>
          </div>
        )}
      </div>
    </div>
        <div>
        <table className="predictions-table">
        <thead>
          <tr>
            <th style={{textAlign:"left", width:"15%", fontWeight: "500", color: "#727376", fontSize: "16px"}}>Forecaster</th>
            <th style={{textAlign:"center", fontWeight: "500", color: "#727376", fontSize: "16px"}}>Target</th>
            <th style={{textAlign:"center", fontWeight: "500", color: "#727376", fontSize: "16px"}}>Past Accuracy</th>
            <th style={{textAlign:"center", fontWeight: "500", color: "#727376", fontSize: "16px"}}>%Target Achieved</th>
            <th style={{textAlign:"center", fontWeight: "500", color: "#727376", fontSize: "16px"}}>Forecast For</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedData.slice(0, rowsToShow).map((data, index) => (
            <tr key={index}>
              <td style={{width:"23%", background: 'var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))', 
                borderBottom:"6px solid #fff",
                padding: "5px 7px",
                color: "#000",
                fontSize: "16px",
                fontWeight: "500",
                marginLeft: "5px"}}>{data.forecaster_name}</td>
              <td style={{textAlign:"center",color: "black",
                    fontWeight: "500",
                    fontSize: "14px",
                    paddingLeft: "10px",}}>{data.forecast_price}</td>
              <td
                style={{textAlign:"center"}}
                onClick={() => openModal(data)}
              >
                <span className={data.accuracy.toLowerCase() !== 'pending' ? 'clickable' : 'pending'}>
                {data.accuracy}
                </span>
              </td>
              <td style={{textAlign:"center",color: "black",
                    fontWeight: "500",
                    fontSize: "14px",
                    paddingLeft: "10px",}}>{data.target_percent}</td>
              <td style={{textAlign:"center",color: "black",
                    fontWeight: "500",
                    fontSize: "14px",
                    paddingLeft: "10px",}}>{data.target_date}</td>
              <td>
                <div className="dropdown">
                  <button style={{ width: '20px' }} onClick={() => toggleDropdown(index)} className="dropdown-button">
                    <img src={window.constants.asset_path + '/images/more.png'} style={{ width: '20px', height: '20px', marginRight: '5px' }} alt="More" />
                  </button>
                  {dropdownVisible === index && (
                    <div className="dropdown-content">
                      <a href={data.source_link} target="_blank"><img src={window.constants.asset_path + '/images/external-link-symbol.png'} style={{ width: '20px', height: '20px', marginRight: '5px' }} alt="Copy" />Source link</a>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
          {rowsToShow < sortedData.length && (
              <StyledTableRow>
                <StyledTableCell colSpan={7} align="center">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button onClick={handleLoadMore} 
                    className="load-more"
                    style={{ 
                      maxWidth: "360px",
                      width: "80vw",
                      backgroundColor: "rgba(67, 97, 238, 0.15)",
                      borderRadius: "6px",
                      border: "none",
                      textTransform: "none",
                      borderStyle: "solid",
                      borderColor: "rgba(67, 97, 238, 0.15)",
                      borderWidth: "1px",
                      color: "#4361ee",
                      width: "15%",
                      height: "35px",
                      cursor: "pointer" }}>
                      Explore More
                    </button>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            )}
        </tbody>
      </table>
      </div>
      </div>
      </div>
      ) : (
      <div style={{ position: 'relative', overflowX: 'auto', marginBottom:"12%" }}>
      <table className="predictions-table">
        <thead>
          <tr>
            <th style={{minWidth: mediaQueryVar1===false ? "" : "150px" ,textAlign:"left", fontWeight: "500", color: "#727376", fontSize: "16px"}}>Forecaster</th>
            <th style={{textAlign:"center", fontWeight: "500", color: "#727376", fontSize: "16px"}}>Target</th>
            <th style={{textAlign:"center", fontWeight: "500", color: "#727376", fontSize: "16px"}}>Past Accuracy</th>
            <th style={{textAlign:"center", fontWeight: "500", color: "#727376", fontSize: "16px"}}>%Target Achieved</th>
            <th style={{textAlign:"center", fontWeight: "500", color: "#727376", fontSize: "16px"}}>Forecast For</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedData.slice(0, rowsToShow).map((data, index) => (
            <tr key={index}>
              <td style={{background: 'var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))', 
                borderBottom:"6px solid #fff",
                padding: "5px 7px",
                color: "#000",
                fontSize: "16px",
                fontWeight: "500",
                marginLeft: "5px",
                }}>{data.forecaster_name}</td>
              <td style={{textAlign:"center",color: "black",
                    fontWeight: "500",
                    fontSize: "14px",
                    paddingLeft: "10px",}}>{data.forecast_price}</td>
              <td
                style={{textAlign:"center"}}
                onClick={() => openModal(data)}
              >
                <span className={data.accuracy.toLowerCase() !== 'pending' ? 'clickable' : 'pending'}>
                {data.accuracy}
                </span>
              </td>
              <td style={{textAlign:"center",color: "black",
                    fontWeight: "500",
                    fontSize: "14px",
                    paddingLeft: "10px",}}>{data.target_percent}</td>
              <td style={{textAlign:"center",color: "black",
                    fontWeight: "500",
                    fontSize: "14px",
                    paddingLeft: "10px",}}>{data.target_date}</td>
              <td>
                <div className="dropdown">
                  <button style={{ width: '20px' }} onClick={() => toggleDropdown(index)} className="dropdown-button">
                    <img src={window.constants.asset_path + '/images/more.png'} style={{ width: '20px', height: '20px', marginRight: '5px' }} alt="More" />
                  </button>
                  {dropdownVisible === index && (
                    <div className="dropdown-content">
                      <a href={data.source_link} target="_blank"><img src={window.constants.asset_path + '/images/external-link-symbol.png'} style={{ width: '20px', height: '20px', marginRight: '5px' }} alt="Copy" />Source link</a>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
          {rowsToShow < sortedData.length && (
              <StyledTableRow>
                <StyledTableCell colSpan={7} align="center">
          <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
            <button
              onClick={handleLoadMore}
              className="load-more"
              style={{
                backgroundColor: "rgba(67, 97, 238, 0.15)",
                borderRadius: "6px",
                border: "none",
                textTransform: "none",
                borderStyle: "solid",
                borderColor: "rgba(67, 97, 238, 0.15)",
                borderWidth: "1px",
                color: "#4361ee",
                height: "35px",
                cursor: "pointer",
                marginTop:"15px"
              }}
            >
              Explore More
            </button>
          </div>
        </StyledTableCell>
        </StyledTableRow>
            )}
        </tbody>
      </table>
      </div>
      )}
      {mediaQueryVar === false && (


<div
  style={{
    position: "fixed",
    bottom: "0",
    backgroundColor: "white",
    width: "100%",
    zIndex: "100",
    marginBottom:"0px",
    marginLeft:"-5%"
  }}
>
<BottomNavigation showLabels>
  <BottomNavigationAction
        label="Gallery"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => 
          navigate("/my-portfolio")
        }
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
     <BottomNavigationAction
       label="Profile"
       onClick={() => 
      navigate("/account")
  }
  icon={<img src={loc==='/account'? "/images/dollarS.svg":"/images/dollarU.svg"} width={22} height={22} alt="Icon" style={{marginBottom : "-3px"}}/>}
/>
</BottomNavigation>
</div>
)}
      <Modal 
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
          marginLeft:mediaQueryVar===true ? "20%" :""
        },
        content: {
          align:"center",
          width:"80%",
          marginBottom:"20%",
          height:"auto",
          borderRadius: '8px',
          position: 'center',
        }
      }}
       isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Accuracy Details">
        <img
            className="frameIcon"
            alt=""
            onClick={closeModal}
            src="/images/frame17.svg"
            style={{ background: "#F3F3F3", width: "25px", height: "25px", marginTop:"1.5%", marginBottom:"1%"
            }}
         />
        <h4>Past Accuracy</h4>
        {modalData ? (
          <table className="modal-table">
            <thead>
              <tr>
                <th>Published On</th>
                <th>Forecast Date</th>
                <th style={{textAlign:"center"}}>Forecast Price</th>
                <th style={{textAlign:"center"}}>Accuracy</th>
              </tr>
            </thead>
            <tbody>
              {modalData.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.publish_date}</td>
                  <td >{detail.target_date} 11:59 PM</td>
                  <td style={{textAlign:"center"}}>{detail.forecast_price}</td>
                  <td style={{textAlign:"center"}}>{detail.accuracy}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No data available</p>
        )}
      </Modal>
    </div>
  );
      
};

export default CryptoPage;