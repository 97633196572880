//login call
const getCryporCurrencyList = (data) => {
    console.log(data);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", data.Token);
    
    var raw = JSON.stringify({
        "user_id": data.user_id,
        "slug": data.slug,
        "start_index": data.start_index,
        "limit": data.limit,
    }); 
    console.log(data);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(`${process.env.REACT_APP_API_URL}/cryptocurrencylist`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));
};

const getTotalunit = (data) => {
    console.log(data);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", data.Token);
    
    var raw = JSON.stringify({
        "user_id": data.user_id,
        "slug": data.slug,
        "portfolio_type_id": data.portfolio_type_id,
    }); 
    console.log(data);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(`${process.env.REACT_APP_API_URL}/gettotalunit`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));
};

const getFetchdropdown = (data) => {
    console.log(data);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", data.Token);
    
    var raw = JSON.stringify({
        "user_id": data.user_id,
    }); 
    console.log(data);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(`${process.env.REACT_APP_API_URL}/getallportfolio`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));
};

module.exports = {
    getCryporCurrencyList,
    getTotalunit,
    getFetchdropdown
};
